import { useMemo, useState } from "react";

function useConfirmDialog() {
  const [showConfirmDialog, setShowConfirmDialog] = useState<Boolean>(false);
  const [action, setAction] = useState<Function>(() => null);
  const [message, setMessage] = useState<any>("");

  const values = useMemo(() => {
    return {
      showConfirmDialog,
      setShowConfirmDialog,
      action,
      setAction,
      message,
      setMessage,
    };
  }, [action, message, showConfirmDialog]);

  return values;
}

export default useConfirmDialog;
