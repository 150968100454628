import MenuIcon from "components/icons/MenuIcon";
import NotificationBell from "components/icons/NotificationBell";
import { AuthUser } from "contexts/AuthUser";
import { CLContext } from "contexts/CLContext";
import useDocumentTitle from "hooks/useDocumentTitle";
import { useContext } from "react";

import styles from "./header.module.css";

function Header() {
  const { title } = useDocumentTitle();
  const { user } = useContext(AuthUser);
  const { setShowModal } = useContext(CLContext);

  return (
    <header className={`flex justify-between items-center gap-1`}>
      <MenuIcon
        className={styles.menu__icon}
        onClick={() => setShowModal((n: Boolean) => !n)}
      />

      <h1 className={styles.page__title}>{title}</h1>

      <div className={`flex justify-between items-center gap-1`}>
        <div className="">
          <NotificationBell />
        </div>
        <div className={styles.admin__details}>
          <img src={`data:image/svg+xml,%3Csvg stroke='currentColor' fill='%23878787' stroke-width='0' viewBox='0 0 512 512' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M399 384.2C376.9 345.8 335.4 320 288 320H224c-47.4 0-88.9 25.8-111 64.2c35.2 39.2 86.2 63.8 143 63.8s107.8-24.7 143-63.8zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm256 16a72 72 0 1 0 0-144 72 72 0 1 0 0 144z'%3E%3C/path%3E%3C/svg%3E`} alt="Admin" />

          <div className="">
            <p className={styles.admin__name}>
              {user?.firstName} {user?.lastName}
            </p>
            <p className={styles.admin__role}>Administrator</p>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
