import * as React from "react"

function VideoIcon(props:any) {
  return (
    <svg
      width={50}
      height={50}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <path fill="url(#pattern0)" d="M0 0H50V50H0z" />
      <defs>
        <pattern
          id="pattern0"
          patternContentUnits="objectBoundingBox"
          width={1}
          height={1}
        >
          <use xlinkHref="#image0_6772_6912" transform="scale(.01042)" />
        </pattern>
        <image
          id="image0_6772_6912"
          width={96}
          height={96}
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAACXBIWXMAAAsTAAALEwEAmpwYAAADGUlEQVR4nO2cy2oUQRSGP1wkQpQEFdS9ax/BxJ3BrbqLiY+gMfoUvoKCa03cu3HjZaGoWWhcJRi3k4AJigQpKShh0O6evtRU1XT9HxwGZorTp/+/b9NdfUAIIYQQQgghhBBCCCFEMXPACrABbAGHgMk0Dp0G68Cy02ZsHAfuAfsJrLhJNPaANWDat/jngTcJrKCZkHgNnPMp/k4CK2UmLHZ8mGB3JW35tDbhVdfD0f0EtiQz4bHaVvy5ESfcJ8A8MEO+zDgN1it0GgCzbZKvVCS97X9dJp7VCr1utkm4UZLsqf/ae4NXzb6UJLvkv+7eMF+imf2z1pjvJclODI25Cnwb+m0XWKzI2ffxJ0s0s1o2pux4Nsxuwe9fK3LmMN7U0K0WdRI1XVgO440MIB8DUtjlUxsf1IDFf4qyxVypyJnDeBPSAPE/MiAyMiAyMiAyMiAyMiAyvTLgGrAJ/HSft4BjpE1vDLhRsvz3wGXSpTcGbFbUYNy99wukR28MOBphgI1fwINxT4jK1QDTIAZuAsFUwPqa1h0vUQADzNCTp+sBa2xSd7xEAQ0wLp4DFwPWWqfueIkiGGCA38Bj4GzAmqvqjpcokgHGhZ3bdDfg+UEGUCzCO+C0DOi+JZkO8UgGxDVgTwbENWAgA+Ia8FAGxDPgLXBKBoQ3YF+Xoe0wHUN/xDpiOoRuRXjAtAjdjPOIaXh5qdvRnjmqIbweyIyRjyPE1yPJADMiTEHooXxgEz4AP9ynpqWEVH9CKTtkxkuUGUYGxEUGREYGREYG5GRA7DfTUxwf1IAUXgtNbXxQA5ouLIfxRgaQjwEp7PKpjfdmQJ12NSm8mZ7SeK/tasoaNtmmRKKYBZ8Nm8oa0dnvRTHPKhocNmZ5HK0Ye8xahV5LbRLOubmUVU+k7C6Xe9vKhYotv1PbylGuKqilQacWn9OuEbXEppUGL328GGIbUG/LBJqKv+3z9agzwAuZQJOm3d7a1/9lyl392JOKDkmUnnDvjPt9tFnXC9m24/0MHGRsyAHwyWmx1OVqRwghhBBCCCGEEEIIIeg1fwACLQ9RsUOUagAAAABJRU5ErkJggg=="
        />
      </defs>
    </svg>
  )
}

export default VideoIcon
