import { useState } from "react";
import FilterOptions from "./FilterOptions";
import styles from "./filter.module.css";
import { JSXElement } from "@babel/types";

type FilterProps = {
  options?: any[];
  onOptionChange?: (value: any) => void;
  onSearchChange?: (value: any) => void;
  searchPlaceholder?: string;
  allowMultipleSelections?: Boolean;
  optionsTitle?: string | JSXElement;
};

function Filter({
  options,
  onOptionChange,
  onSearchChange,
  optionsTitle,
  searchPlaceholder,
  allowMultipleSelections,
}: FilterProps) {
  const [showFilterOptions, setShowFilterOptions] = useState<Boolean>(false);

  return (
    <div className={styles.filter__box}>
      <div className={styles.filter__input}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={25}
          height={25}
          viewBox="0 0 25 25"
          fill="none"
          className={styles.filter__search__icon}
        >
          <mask
            id="mask0_5634_1347"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x={0}
            y={0}
            width={25}
            height={25}
          >
            <rect x="0.390747" y="0.5" width={24} height={24} fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_5634_1347)">
            <path
              d="M9.89075 16.5C8.07408 16.5 6.53658 15.8708 5.27825 14.6125C4.01991 13.3542 3.39075 11.8167 3.39075 10C3.39075 8.18333 4.01991 6.64583 5.27825 5.3875C6.53658 4.12917 8.07408 3.5 9.89075 3.5C11.7074 3.5 13.2449 4.12917 14.5032 5.3875C15.7616 6.64583 16.3907 8.18333 16.3907 10C16.3907 10.7333 16.2741 11.425 16.0407 12.075C15.8074 12.725 15.4907 13.3 15.0907 13.8L20.6907 19.4C20.8741 19.5833 20.9657 19.8167 20.9657 20.1C20.9657 20.3833 20.8741 20.6167 20.6907 20.8C20.5074 20.9833 20.2741 21.075 19.9907 21.075C19.7074 21.075 19.4741 20.9833 19.2907 20.8L13.6907 15.2C13.1907 15.6 12.6157 15.9167 11.9657 16.15C11.3157 16.3833 10.6241 16.5 9.89075 16.5ZM9.89075 14.5C11.1407 14.5 12.2032 14.0625 13.0782 13.1875C13.9532 12.3125 14.3907 11.25 14.3907 10C14.3907 8.75 13.9532 7.6875 13.0782 6.8125C12.2032 5.9375 11.1407 5.5 9.89075 5.5C8.64075 5.5 7.57825 5.9375 6.70325 6.8125C5.82825 7.6875 5.39075 8.75 5.39075 10C5.39075 11.25 5.82825 12.3125 6.70325 13.1875C7.57825 14.0625 8.64075 14.5 9.89075 14.5Z"
              fill="#828282"
            />
          </g>
        </svg>

        <input
          type="text"
          name="club__name"
          className={styles.club__name}
          placeholder={searchPlaceholder}
          onChange={(e) => onSearchChange?.(e.target.value)}
        />
      </div>

      {/* Show List of Options if there are options */}
      {options && (
        <div className="">
          <div
            className={styles.filter__icon}
            onClick={() => setShowFilterOptions(true)}
          >
            <svg
              className=""
              viewBox="175.7191 202.569 16.0412 16"
              width="16.0412"
              height={16}
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M 182.74 218.569 C 182.456 218.569 182.219 218.473 182.027 218.282 C 181.836 218.09 181.74 217.852 181.74 217.569 L 181.74 211.569 L 175.94 204.169 C 175.69 203.836 175.652 203.486 175.827 203.119 C 176.002 202.752 176.306 202.569 176.74 202.569 L 190.74 202.569 C 191.173 202.569 191.477 202.752 191.652 203.119 C 191.827 203.486 191.79 203.836 191.54 204.169 L 185.74 211.569 L 185.74 217.569 C 185.74 217.852 185.644 218.09 185.452 218.282 C 185.261 218.473 185.023 218.569 184.74 218.569 L 182.74 218.569 Z M 183.74 210.869 L 188.69 204.569 L 178.79 204.569 L 183.74 210.869 Z"
                fill="#333333"
                transform="matrix(1, 0, 0, 1, -7.105427357601002e-15, 0)"
              />
            </svg>
          </div>

          <FilterOptions
            title={optionsTitle}
            options={options}
            open={showFilterOptions}
            closeFn={() => setShowFilterOptions(false)}
            onChange={onOptionChange}
            allowMultipleSelections={allowMultipleSelections}
          />
        </div>
      )}
    </div>
  );
}

export default Filter;
