import * as React from "react"

function IncreaseMoneyIcon(props:any) {
  return (
    <svg
      width={25}
      height={24}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.85 6l.54-.59V9a1 1 0 002 0V5.41l.54.55a1.001 1.001 0 001.46.04 1 1 0 000-1.42L13.1 2.29a1 1 0 00-.33-.21 1 1 0 00-.76 0 1 1 0 00-.33.21L9.39 4.54A1.032 1.032 0 0010.85 6zm1.54 6a3 3 0 100 6 3 3 0 000-6zm0 4a1 1 0 110-2 1 1 0 010 2zm-7-1a1 1 0 102 0 1 1 0 00-2 0zm14 0a1 1 0 10-2 0 1 1 0 002 0zm1-7h-4a1 1 0 000 2h4a1 1 0 011 1v8a1 1 0 01-1 1h-16a1 1 0 01-1-1v-8a1 1 0 011-1h4a1 1 0 100-2h-4a3 3 0 00-3 3v8a3 3 0 003 3h16a3 3 0 003-3v-8a3 3 0 00-3-3z"
        fill="#009C9C"
      />
    </svg>
  )
}

export default IncreaseMoneyIcon
