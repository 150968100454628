import * as React from "react"

function OverviewIcon({ ...props }:any) {
  return (
    <svg
      width={25}
      height={25}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.782 12.389h7.11V3.5h-7.11v8.889zm0 7.111h7.11v-5.333h-7.11V19.5zm8.888 0h7.112v-8.889H12.67V19.5zm0-16v5.333h7.112V3.5H12.67z"
        fill="#303030"
      />
    </svg>
  )
}

export default OverviewIcon
