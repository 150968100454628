import defaultAd from "assets/images/default-ad.png";
import CheckIcon from "components/icons/CheckIcon";
import Trash from "components/icons/Trash";
import moment from "moment";
import styles from "./advertisments.module.css";
import { AdCardTypes } from "./types";

function AdCard({
  id,
  file,
  filetype,
  companyName,
  endDate,
  status,
  actions,
  handleRemove
}: AdCardTypes) {
  let isActive = status?.toLowerCase() === "active".toLowerCase();
  filetype = filetype
    ? filetype
    : new RegExp(/\.mp4|\.webm|\.avi|\.mkv/gi).test(file as string)
    ? "video"
    : "image";

  return (
    <div className={styles.card}>
      {status && <CheckIcon className={styles.icon} disabled={!isActive} />}

      <div className={styles.card__media}>
        {filetype === "video" ? (
          <video src={file as string} poster={!file ? defaultAd : ""} controls></video>
        ) : (
          <img src={file || defaultAd} alt={companyName} />
        )}

        {id && (
          <div className={styles.remove__ad__icon}>
            <Trash
              height={14}
              onClick={() => handleRemove?.(id, companyName)}
              // title={`Delete ${hometeam_name} vs ${awayteam_name}`}
            />
          </div>
        )}
      </div>

      <div className={styles.card__body}>
        <div className={`${styles.card__details}`}>
          <span className={styles.card__company}>
            Company Name: {companyName}
          </span>
          <span className={styles.card__date}>
            {moment(endDate).format("DD MMM YYYY")}
          </span>
        </div>

        {actions && (
          <div className={styles.buttons_group}>
            <button
              className={`${styles.button} ${
                isActive ? styles.disabled_button : styles.active_button
              }`}
              onClick={() => null}
            >
              Activate
            </button>
            <button
              className={`${styles.button} ${
                isActive ? styles.active_button : styles.disabled_button
              }`}
              onClick={() => null}
            >
              Deactivate
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default AdCard;
