import { useLocation, useNavigate } from "react-router-dom";

export default function GoBack() {
  const navigate = useNavigate();
  const location = useLocation();

  if(location.key === "default" || location.pathname === "/") {
    return <></>;
  }

  return (
    <button
      onClick={() => navigate(-1)}
      type="button"
      className="flex items-center gap-0.5 goback"
    >
      <svg
        width={25}
        height={24}
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.1953 12H5.19531"
          stroke="#1F1F1F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.1953 19L5.19531 12L12.1953 5"
          stroke="#1F1F1F"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>

      <span>Back</span>
    </button>
  );
}
