import React from 'react'
import style from './userDetails.module.css'
import useGetData from 'hooks/useGetData';
import dumm from './rex_admin.jpeg'
import { useParams } from 'react-router-dom';
interface Users {
    success: Boolean,
    data: {
        id: number;
        firstName: string;
        lastName: string;
        email: string;
        username: string;
        gender: string;
        age: number;
        DOB: string;
        userId: number;
        nationality: string;
        updatedAt: string;
        createdAt: string;
    };
}
const UserDetails = () => {
    const { id } = useParams();

    const { data } = useGetData<Users>(`/admin/get-user-details/${id}`);
    // const { data } = useGetData<Users>(`/profile/get-profile-by-user-details-id/${id}`);

    const result = data?.data




    return (
        <div className={style.user__details__containner}>
            <div className={style.user__details__show}>
                <p className={style.user__details__show1}>User's Details</p>
                <p className={style.user__details__show2}>The user’s details are shown here.</p>
                <hr className={style.user__details__show3} />
            </div>
            <div className={style.bottom__user__container_all}>
                <span>
                    <img className={style.user__image} src={dumm} alt="user" />
                    <hr className={style.bottom__user__underline} />
                </span>
                <div className={style.bottom__user__container} >
                    <div className={style.bottom__user__div} >
                        <h3>Full Name</h3>
                        {/* <p>Adelanke Adelanke</p> */}
                        <p>{result?.firstName} {result?.lastName}</p>
                    </div>
                    <hr className={style.bottom__user__underline} />
                    {/* spacing */}
                    <div className={style.bottom__user__div} >
                        <h3>Username</h3>
                        <p>{result?.username}</p>
                    </div>
                    <hr className={style.bottom__user__underline} />
                    <div className={style.bottom__user__div} >
                        <h3>Email Address</h3>
                        <p>{result?.email}</p>
                    </div>
                    <hr className={style.bottom__user__underline} />
                    <div className={style.bottom__user__div} >
                        <h3>Accumulated Points</h3>
                        <p>10,500</p>
                    </div>
                    <hr className={style.bottom__user__underline} />
                    <div className={style.bottom__user__div} >
                        <h3>Current Position On Leaderboard</h3>
                        <p>2nd</p>
                    </div>
                    <hr className={style.bottom__user__underline} />
                    <div className={style.bottom__user__div} >
                        <h3>Total Prize Won</h3>
                        <p>$500.00</p>
                    </div>
                    <hr className={style.bottom__user__underline} />
                    <div className={style.bottom__user__div} >
                        <h3>Full Name</h3>
                        <p>Adelanke Adelanke</p>
                    </div>
                    <hr className={style.bottom__user__underline} />
                </div>
            </div>
        </div>
    )
}

export default UserDetails
