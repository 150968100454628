import React from "react";
import styles from "./summary-card.module.css";

type SummaryCardTypes = {
  label: string,
  value: number | undefined,
  icon: React.ReactNode,
  bg?: string
}

function SummaryCard ({ label, value, icon, bg, }: SummaryCardTypes) {
  return (
    <div className={`flex gap-1 ${bg || "bg-primary-200"}`}>
      <div className="space-y-0.5">
        <p className={`${styles.card__label} text-uppercase`}>{label || ""}</p>
        <p className={styles.card__value}>
          {Number(value || 0).toLocaleString()}
        </p>
      </div>

      <div className={`flex justify-center items-center`}>
        <div className={styles.card__icon}>
          {icon}
        </div>
      </div>
    </div>
  );
}

export default SummaryCard;
