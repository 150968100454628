import { useFormikContext } from "formik";
import { useState } from "react";

import styles from "./input.module.css";

export default function Input({
  label,
  is_password_field,
  error,
  type,
  ...props
}: any) {
  const [show_password, setShowPassword] = useState<Boolean>(false);

  const { values, errors, touched, handleChange, handleBlur }: any =
    useFormikContext();

  return (
    <div className={styles.input__group}>
      <label htmlFor={props.name}>{label}</label>

      <div
        className={`${styles.input__box} ${
          errors?.[props.name] && touched?.[props.name]
            ? `${styles.input__error}`
            : ""
        }`}
      >
        <input
          className={styles.input__field}
          type={
            is_password_field ? (show_password ? "text" : "password") : type
          }
          onChange={handleChange}
          onBlur={handleBlur}
          value={values?.[props.name]}
          {...props}
        />

        {is_password_field && (
          <span className={`px-1`} onClick={() => setShowPassword((n) => !n)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
            >
              <path
                d="M22.7247 11.6C20.7047 6.91 16.9047 4 12.8047 4C8.70469 4 4.90469 6.91 2.88469 11.6C2.82963 11.7262 2.80121 11.8623 2.80121 12C2.80121 12.1377 2.82963 12.2738 2.88469 12.4C4.90469 17.09 8.70469 20 12.8047 20C16.9047 20 20.7047 17.09 22.7247 12.4C22.7798 12.2738 22.8082 12.1377 22.8082 12C22.8082 11.8623 22.7798 11.7262 22.7247 11.6ZM12.8047 18C9.63469 18 6.63469 15.71 4.90469 12C6.63469 8.29 9.63469 6 12.8047 6C15.9747 6 18.9747 8.29 20.7047 12C18.9747 15.71 15.9747 18 12.8047 18ZM12.8047 8C12.0136 8 11.2402 8.2346 10.5824 8.67412C9.92462 9.11365 9.41193 9.73836 9.10917 10.4693C8.80642 11.2002 8.72721 12.0044 8.88155 12.7804C9.03589 13.5563 9.41686 14.269 9.97627 14.8284C10.5357 15.3878 11.2484 15.7688 12.0243 15.9231C12.8003 16.0775 13.6045 15.9983 14.3354 15.6955C15.0663 15.3928 15.691 14.8801 16.1306 14.2223C16.5701 13.5645 16.8047 12.7911 16.8047 12C16.8047 10.9391 16.3833 9.92172 15.6331 9.17157C14.883 8.42143 13.8656 8 12.8047 8ZM12.8047 14C12.4091 14 12.0225 13.8827 11.6936 13.6629C11.3647 13.4432 11.1083 13.1308 10.9569 12.7654C10.8056 12.3999 10.766 11.9978 10.8431 11.6098C10.9203 11.2219 11.1108 10.8655 11.3905 10.5858C11.6702 10.3061 12.0266 10.1156 12.4145 10.0384C12.8025 9.96126 13.2046 10.0009 13.5701 10.1522C13.9355 10.3036 14.2479 10.56 14.4676 10.8889C14.6874 11.2178 14.8047 11.6044 14.8047 12C14.8047 12.5304 14.594 13.0391 14.2189 13.4142C13.8438 13.7893 13.3351 14 12.8047 14Z"
                fill="#303030"
              />
            </svg>
          </span>
        )}
      </div>

      {errors?.[props.name] && touched?.[props.name] && (
        <span className={styles.error__msg}>{errors?.[props.name]}</span>
      )}
    </div>
  );
}
