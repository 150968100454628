import { ReactNode, useEffect, useRef, useState } from "react";
import styles from "./select.module.css";

type RefValues = {
  current: HTMLDivElement | null;
};

type SelectProps = {
  defaultValue?: string;
  options: {
    icon?: string;
    value: string | number;
    placeholder: string | ReactNode;
  }[];
  onChange?: (value: string | number) => void;
};

function Select({ onChange, defaultValue, options }: SelectProps) {
  const selectRef: RefValues = useRef(null);
  const searchRef: RefValues = useRef(null);
  const [selectedValue, setSelectedValue] = useState<string | number>("");
  const [showOptions, setShowOptions] = useState<Boolean>(false);
  const [search, setSearch] = useState<string>("");

  const handleClickAway = (e: any) => {
    if (
      searchRef.current === e.target ||
      searchRef.current?.contains(e.target as Node)
    ) {
      return null;
    }

    if (!selectRef.current?.contains(e.target as Node)) {
      return setShowOptions(false);
    }
  };

  useEffect(() => {
    if (onChange) {
      onChange(selectedValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue]);

  useEffect(() => {
    window.addEventListener("click", handleClickAway);

    return () => {
      window.removeEventListener("click", handleClickAway);
    };
  }, []);

  return (
    <div ref={selectRef} className={styles.select__box}>
      <div
        className={styles.select__current__item}
        onClick={() => setShowOptions(true)}
      >
        <span className="">
          {(options?.find(({ value }) => value === selectedValue)
            ?.placeholder as ReactNode) ||
            defaultValue ||
            "Choose Option"}
        </span>
        <svg
          width="14"
          height="8"
          viewBox="0 0 14 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.37146 0H12.8371C13.868 0 14.3835 1.24035 13.6561 1.96349L7.92325 7.66238C7.47041 8.11254 6.73815 8.11254 6.29012 7.66238L0.552486 1.96349C-0.174956 1.24035 0.340516 0 1.37146 0Z"
            fill="#353A45"
          />
        </svg>
      </div>

      {showOptions && (
        <div className={styles.select__options}>
          <div ref={searchRef} className={styles.select__search__container}>
            <input
              className={styles.select__search__input}
              type="text"
              placeholder="Search League"
              id="search__bar"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSearch(e.target.value)
              }
              value={search}
            />
          </div>
          {options
            .filter(({ placeholder }: any) =>
              new RegExp(search.toLowerCase()).test(placeholder?.toLowerCase())
            )
            .map(({ icon, value, placeholder }, index) => (
              <div
                key={index}
                className={styles.option}
                onClick={() => {
                  setSelectedValue(value);
                  setShowOptions(false);
                  setSearch("");
                }}
              >
                {icon ? (
                  <img
                    className={styles.icon}
                    src={icon}
                    alt=""
                    loading="lazy"
                    onError={(e) => e.currentTarget.src = "data:image/svg+xml,%3Csvg stroke='currentColor' fill='currentColor' stroke-width='0' viewBox='0 0 16 16' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.775 11.775 0 0 1-2.517 2.453 7.159 7.159 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7.158 7.158 0 0 1-1.048-.625 11.777 11.777 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 62.456 62.456 0 0 1 5.072.56z'%3E%3C/path%3E%3C/svg%3E"}
                  />
                ) : (
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth={0}
                    viewBox="0 0 16 16"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 011.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.775 11.775 0 01-2.517 2.453 7.159 7.159 0 01-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7.158 7.158 0 01-1.048-.625 11.777 11.777 0 01-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 012.185 1.43 62.456 62.456 0 015.072.56z"
                      stroke="none"
                    />
                  </svg>
                )}
                {placeholder as ReactNode}
              </div>
            ))}
        </div>
      )}
    </div>
  );
}

export default Select;
