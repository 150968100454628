import * as React from "react"

function Trash(props:any) {
  return (
    <svg
      width={19}
      height={20}
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.39 16a1 1 0 001-1V9a1 1 0 10-2 0v6a1 1 0 001 1zm10-12h-4V3a3 3 0 00-3-3h-2a3 3 0 00-3 3v1h-4a1 1 0 000 2h1v11a3 3 0 003 3h8a3 3 0 003-3V6h1a1 1 0 100-2zm-10-1a1 1 0 011-1h2a1 1 0 011 1v1h-4V3zm7 14a1 1 0 01-1 1h-8a1 1 0 01-1-1V6h10v11zm-3-1a1 1 0 001-1V9a1 1 0 10-2 0v6a1 1 0 001 1z"
        fill="#B00020"
      />
    </svg>
  )
}

export default Trash
