import { useEffect, useState } from "react";
import { useMatches } from "react-router-dom";


export default function useDocumentTitle(default_title?: string) {
  const matches = useMatches();
  const pageTitle = String(matches[matches.length - 1].handle);
  const [title, setTitle] = useState<string>(default_title || pageTitle);
  
  useEffect(() => {
    document.title = `${title || "Loading"} - 3Scorers Console`;
  }, [title]);

  useEffect(() => {
    setTitle(pageTitle);
    document.title = `${pageTitle || "Loading"} - 3Scorers Console`;
  }, [pageTitle]);

  return { title, setTitle };
}
