import AuthLayout from "layouts/AuthLayout";
import ConsoleLayout from "layouts/ConsoleLayout";
import ProtectedRoute from "middlewares/ProtectedRoute";
import Advertisments from "pages/Advertisments";
import CreateAdvert from "pages/Advertisments/CreateAdvert";
import ErrorPage from "pages/ErrorPage";
import Login from "pages/Login";
import Logout from "pages/Logout";
import Matches from "pages/Matches";
import Overview from "pages/Overview";
import UserDetails from "pages/Overview/UserDetails";
import SetRewards from "pages/SetRewards";
import SetHowToPlay from "pages/Set-How-To-Play";
import { RouteObject } from "react-router-dom";
import AvailablePredictions from "pages/Notifications/AvailablePredictions";
// import CreateNotification from "pages/Notifications";
import EditNotification from "pages/Notifications/EditNotification";
import DeadlinePredictions from "pages/Notifications/DeadlinePredictions";
import StatusPredictions from "pages/Notifications/StatusPredictions";
import SendNotifications from "pages/Notifications/SendNotifications";

const routes: RouteObject[] = [
  {
    path: "/",
    errorElement: <ErrorPage />,
    children: [
      {
        element: <AuthLayout />,
        children: [
          { index: true, element: <Login />, handle: "Login" },
          { path: "sign-up", element: <p>Sign Up Page</p>, handle: "Sign Up" },
          {
            path: "forgot-password",
            element: <p>Forgot Password Page</p>,
            handle: "Forgot Password",
          },
          {
            path: "reset-password",
            element: <p>Reset Password Page</p>,
            handle: "Resest Password",
          },
        ],
      },


      {
        element: <ProtectedRoute />,
        children: [
          {
            element: <ConsoleLayout />,
            children: [
              { path: "overview", element: <Overview />, handle: "Overview" },
              { path: "how-to-play", element: <SetHowToPlay />, handle: "How To Play" },
              { path: "matches", element: <Matches />, handle: "Matches" },
              { path: "overview/:id", element: <UserDetails />, handle: "Overview" },
              { path: "set-rewards", element: <SetRewards />, handle: "Set Rewards" },
              { path: "advertisments",
                children: [
                  { index: true, element: <Advertisments />, handle: "Advertisments" },
                  { path: "create", element: <CreateAdvert />, handle: "Add New" },
                ],
              },
              { path: "send-notifications", element: <SendNotifications />, handle: "New Notification" },
              // { path: "notification",
              //   children: [
              //     // { path: "create", element: <CreateNotification />, handle: "New Notification" },
              //     { path: "edit/:id", element: <EditNotification />, handle: "Edit Notification" },
              //     { path: "predictions-available", element: <AvailablePredictions />, handle: "Available Predictions" },
              //     { path: "predictions-deadline", element: <DeadlinePredictions />, handle: "Deadline Predictions" },
              //     { path: "predictions-status", element: <StatusPredictions />, handle: "Status Predictions" },
              //   ],
              // },
            ],
          },
        ],
      },
      { path: "/logout", element: <Logout />, handle: "Logout" },
    ],
  },
];

export default routes;
