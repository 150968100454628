import moment from "moment";
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import deleteData from "utils/deleteData";
import getData from "utils/getData";
import Filter from "./Filter";
import SingleSelectedMatch from "./SelectedSIngleMatch/index";
import styles from "./matches.module.css";
import { WeeklyPredictions } from "./types";

import ConfirmDialog from "components/ConfirmDialog";
import useConfirmDialog from "components/ConfirmDialog/useConfirmDialog";
import { GrUpdate } from "react-icons/gr";
import { IoCreateOutline } from "react-icons/io5";
import patchData from "utils/patchData";
import postData from "utils/postData";

export default function SelectedMatches({
  predictions,
  setPredictions,
  selectedMatches,
  setSelectedMatches,
}: any) {
  const __INITIAL_RENDER__ = useRef(true);
  const [gameWeek, setGameWeek] = useState<number>(moment().week());
  const [filters, setFilters]: any = useState({
    search: "",
  });

  const handleRemove = (id: string) => {
    const removePrediction = async () => {
      const response = await deleteData(`/prediction/delete-prediction/${id}`);

      if (response.success) {
        setPredictions((n: WeeklyPredictions) => ({
          ...n,
          [moment().week()]: n?.[moment().week()]?.filter(
            (item) => item.matchId !== id
          ),
        }));
      }

      setSelectedMatches((n: string[]) => n.filter((item) => item !== id));
    };

    toast.promise(new Promise((resolve) => resolve(removePrediction())), {
      loading: `Removing prediction`,
      error: `Error removing predictions`,
      success: `Prediction removed`,
    });
  };


  /*** User Point Functions ***/ 

  const dialogValues = useConfirmDialog();

  const handleCreate = () => {
    const createUserPoints = async () => {
      const loadingToastId = toast.loading("Creating user points...");

      try {
        const resp = await postData(`/admin/create-user-point`, {
          gameWeek,
          season: localStorage.getItem("season") ?? "2023/2024",
        });

        toast.remove(loadingToastId);
        if (!resp.success) {
          return toast.success(resp.message, { icon: "ℹ️" });
        }

        toast.success(resp.message);
      } catch (e) {
        toast.remove(loadingToastId);
        toast.error("Error occured while creating user points");
      }
    };

    dialogValues.setMessage(
      <span>
        Are you sure you want to <strong>create</strong> new user points
      </span>
    );

    dialogValues.setAction(() => createUserPoints);
    dialogValues.setShowConfirmDialog(true);
  };

  const handleUpdate = () => {
    const updateUserPoints = async () => {
      const loadingToastId = toast.loading("Updating user points...");
      try {
        const resp = await patchData(`/admin/update-user-point`, {
          gameWeek,
          season: localStorage.getItem("season") ?? "2023/2024",
        });

        toast.remove(loadingToastId);

        if (!resp.success) {
          return toast.success(resp.message, { icon: "ℹ️" });
        }

        toast.success(resp.message ?? "User point successfully updated");
      } catch (e) {
        toast.remove(loadingToastId);
        toast.error("Error occured while creating user points");
      }
    };

    dialogValues.setMessage(
      <span>
        Are you sure you want to <strong>update</strong> user points
      </span>
    );

    dialogValues.setAction(() => updateUserPoints);
    dialogValues.setShowConfirmDialog(true);
  };

  /****  END  ****/



  // useEffect for when a new match is selected
  useEffect(() => {
    const gameWeek = moment().week();

    const getPredictions = async () => {
      const response = await getData(
        "/prediction/get-predictions-by-game-week",
        {
          params: {
            gameWeek,
          },
        }
      );

      if (response.success) {
        return setPredictions((n: WeeklyPredictions) => ({
          ...n,
          [gameWeek]: response?.data,
        }));
      }

      toast.error(response?.data);
    };

    toast.promise(new Promise((resolve) => resolve(getPredictions())), {
      loading: `Updating predictions`,
      error: `Error fetching predictions`,
      success: `Predictions updated`,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMatches]);
  
  // useEffect for see old weeks predictions
  useEffect(() => {

    if(__INITIAL_RENDER__.current){
       __INITIAL_RENDER__.current = false;
       return;
    }

    const getPredictions = async () => {
      const response = await getData(
        "/prediction/get-predictions-by-game-week",
        {
          params: {
            gameWeek,
          },
        }
      );

      if (response.success) {
        return setPredictions((n: WeeklyPredictions) => ({
          ...n,
          [gameWeek]: response?.data,
        }));
      }

      toast.error(response?.data);
    };

    toast.promise(new Promise((resolve) => resolve(getPredictions())), {
      loading: `Fetching Week: ${gameWeek} predictions`,
      error: `Error fetching predictions`,
      success: `Week: ${gameWeek} predictions found`,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameWeek]);

  return (
    <div className={styles.table}>
      <div className={styles.table__head}>
        <p className={styles.table__description}>
          The 5 selected matches to be predicted from are shown here
        </p>
        <div className="">
          <input
            className={`default__input`}
            type="week"
            name=""
            value={`${moment().year()}-W${gameWeek}`}
            onChange={(e) => setGameWeek(parseInt(e.target.value.split("W")[1]))}
          />
        </div>
      </div>

      <div className="flex items-center gap-4 px-4 rounded-xl bg-white">
        <p className="font-bold">User Points</p>

        <div className="ml-auto flex justify-between !gap-2 text-white">
          <button
            className="
              flex justify-center items-center !gap-2 py-2 px-6 
              font-semibold text-sm bg-sky-500 rounded-md
          "
            onClick={handleCreate}
          >
            <IoCreateOutline />
            Create
          </button>
          <button
            className="
            flex justify-center items-center !gap-2 py-2 px-6 
            font-semibold text-sm bg-green-500 rounded-md
          "
            onClick={handleUpdate}
          >
            <GrUpdate />
            Update
          </button>
        </div>
      </div>

      <div className={styles.table__filter__mobile}>
        <Filter
          key={null}
          onSearchChange={(value) => setFilters({ ...filters, search: value })}
          searchPlaceholder="Search Club"
          allowMultipleSelections
        />
      </div>

      <div className={styles.table__body}>
        <div className={`${styles.table__row} ${styles.table__header}`}>
          <div className=""></div>
          <div className={styles.table__column__title}>Matches</div>
          <div className={styles.table__column__title}>Time</div>
          <div className={styles.table__column__title}>Date</div>
          <div className={styles.table__column__title}>Match Status</div>
          {/* <div className=""></div> */}
        </div>

        {predictions?.[gameWeek]?.map((match: any) => {
          let client_tz = moment.tz.guess(true);
          let m = moment.tz(
            `${match.matchDate} ${match.matchTime}`,
            process.env.REACT_APP_TIMEZONE as string
          );
          let match_props = {
            match_id: match.matchId,
            matchStatus: match.matchStatus,
            hometeam_emblem: match.homeLogo,
            awayteam_emblem: match.awayLogo,
            hometeam_name: match.homeTeam,
            awayteam_name: match.awayTeam,
            date: m.tz(client_tz).format("MMMM DD, YYYY"),
            time: m.tz(client_tz).format("h:mma"),
          };

          return (
            <SingleSelectedMatch
              key={match.matchId}
              handleRemove={() => handleRemove(match.matchId)}
              remove_button
              {...match_props}
            />
          );
        })}
      </div>

      <ConfirmDialog {...dialogValues} />
    </div>
  );
}
