import Tabs from "components/Tabs";
import styles from "./matches.module.css";

import { useState } from "react";
import MatchdayLive from "./MatchdayLive";
import SelectedMatches from "./SelectedMatches";
import { WeeklyPredictions } from "./types";

function Matches() {
  const [selectedMatches, setSelectedMatches] = useState<string[] | []>([]);
  const [predictions, setPredictions] = useState<WeeklyPredictions>({});

  

  return (
    <div className={styles.matches}>

      <Tabs
        headings={["Matchday Live", "Selected Matches"]}
        contents={[
          <MatchdayLive
            selectedMatches={selectedMatches}
            setSelectedMatches={setSelectedMatches}
            predictions={predictions}
            setPredictions={setPredictions}
          />,
          <SelectedMatches
            selectedMatches={selectedMatches}
            predictions={predictions}
            setPredictions={setPredictions}
            setSelectedMatches={setSelectedMatches}
          />,
        ]}
      />

      
    </div>
  );
}

export default Matches;
