import { SingleMatchProps } from ".";
import styles from "./match.module.css";

function MobileView({
  hometeam_name,
  hometeam_emblem,
  awayteam_name,
  awayteam_emblem,
  date,
  time,
  loading,
  isSelected,
  handleSelection,
  remove_button,
  handleRemove,
}: SingleMatchProps) {
  return (
    <div className={styles.match__card}>
      <div className={styles.match__body}>
        <div className={styles.match__contestant}>
          <img
            className={styles.logo}
            src={hometeam_emblem}
            alt={hometeam_name}
          />
          <span className={styles.name}>{hometeam_name}</span>
        </div>

        <div className={styles.match__playtime}>
          <p className={styles.time}>{time}</p>
          <p className={styles.date}>{date}</p>
        </div>

        <div className={styles.match__contestant}>
          <img
            className={styles.logo}
            src={awayteam_emblem}
            alt={awayteam_name}
          />
          <span className={styles.name}>{awayteam_name}</span>
        </div>
      </div>
      {remove_button ? (
        <button
          className={`${styles.match__remove__button}`}
          onClick={handleRemove}
        >
          Remove Match
        </button>
      ) : (
        <div className={styles.match__footer}>
          <button
            className={`${styles.match__button} ${
              isSelected && styles.selected
            }`}
            onClick={handleSelection}
          >
            {loading ? (
              <svg
                className={`spin`}
                stroke="currentColor"
                fill="none"
                strokeWidth={0}
                viewBox="0 0 24 24"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 22c5.523 0 10-4.477 10-10h-3a7 7 0 01-7 7v3zM2 12C2 6.477 6.477 2 12 2v3a7 7 0 00-7 7H2z"
                  fill="#000"
                  stroke="none"
                />
              </svg>
            ) : isSelected ? (
              "Unselect"
            ) : (
              "Select"
            )}
          </button>
        </div>
      )}
    </div>
  );
}

export default MobileView;
