import GoBack from 'components/GoBack';
import useDocumentTitle from 'hooks/useDocumentTitle';
import { useRouteError } from 'react-router-dom';

function ErrorPage() {
  const error:any = useRouteError();
  useDocumentTitle(`${error.status} ${error.statusText}`)

  return (
    <main
      className="error-main"
    >

      <div className="flex flex-col justify-center gap-0.5">
        <h1 className="">
          Oops!
        </h1>
        <span className="error-status">{error.status} {error.statusText}</span>
        <div className='flex justify-center items-center'>
          <GoBack />
        </div>
        <p className="">Sorry an unexpexted error has occured!</p>
        <p className="">
          <span className="error-page-message">"{error.data}"</span>
        </p>
      </div>
    </main>
  )
}

export default ErrorPage