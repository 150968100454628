import { IoIosCloseCircle } from "react-icons/io";
import styles from "./styles.module.css";


type ConfirmDialogTypes = {
  showConfirmDialog: Boolean,
  setShowConfirmDialog: React.Dispatch<React.SetStateAction<Boolean>>,
  action: Function,
  setAction: React.Dispatch<React.SetStateAction<Function>>,
  message: string,
  setMessage: React.Dispatch<React.SetStateAction<string>>,
}


function Index({
  showConfirmDialog,
  setShowConfirmDialog,
  action,
  setAction,
  message,
  setMessage
 }: ConfirmDialogTypes) {

  const handleCancel = () => {
    setAction(() => null)
    setMessage("")
    setShowConfirmDialog(false);
  };

  const handleAction = () => {
    action();
    setShowConfirmDialog(false);
  };

  return (
      showConfirmDialog ? (
        <div className={styles.confrim__modal}>
          <div className={styles.overlay}></div>
          <div className={styles.confrim__card}>
            <div className={styles.confirm__card__head}>
              <p className="">Confirm Action</p>
            </div>
            <div className={styles.confirm__card__body}>
              <div className="">
                {message}
              </div>
            </div>
            <div className={styles.confirm__card__footer}>
              <button className={styles.cancel__button} onClick={handleCancel}>
                No
              </button>
              <button className={styles.accept__button} onClick={handleAction}>
                Yes
              </button>
            </div>
          </div>
        </div>
      ) : <></>
  );
}

export default Index