import { useEffect, useRef } from "react";
import styles from "./how.module.css";

interface PreviewProps {
  formInputData: {
    title: string;
    name: string;
    text: string;
  };
  videoFile: File | undefined;
  switchPage: () => void;
  handleSelection: () => void;
}

const Preview: React.FC<PreviewProps> = ({
  formInputData,
  videoFile,
  switchPage,
  handleSelection,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  console.log(videoFile);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.pause();
    }
  }, []);

  const handleVideoClick = () => {
    // Toggle play/pause when the video is clicked
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
  };
  return (
    <>
      <div>
        {" "}
        <div className={styles.previewbutton__container}>
          <button className={styles.upload} onClick={handleSelection}>
            Upload
          </button>
          <button className={styles.preview} onClick={switchPage}>
            Edit
          </button>
        </div>
        <div className={styles.preview__details}>
          {videoFile && (
            <video
              ref={videoRef}
              className={styles.video}
              onClick={handleVideoClick}
              controls
            >
              <source src={URL.createObjectURL(videoFile)} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
          <h2>{formInputData.title}</h2>
          <div dangerouslySetInnerHTML={{__html: formInputData.text}}></div>
        </div>
      </div>
    </>
  );
};

export default Preview;
