import Trash from "components/icons/Trash";
import { SingleMatchProps } from ".";
import styles from "./match.module.css";
import toast from "react-hot-toast";

function MobileView({
  matchStatus,
  match_id,
  hometeam_name,
  hometeam_emblem,
  awayteam_name,
  awayteam_emblem,
  date,
  time,
  loading,
  handleRemove,
  updatePrediction,
}: SingleMatchProps) {
  const isMatchFinished = matchStatus === "finished";
  const handleUpdate = () => {
    if (isMatchFinished && updatePrediction) {
      updatePrediction(match_id);
    } else {
      toast.error("Match must be finished to update prediction.");
    }
  };
  return (
    <div className={styles.match__card}>

        <div className={styles.remove__match__icon}>
          <Trash
            height={14}
            onClick={handleRemove}
            title={`Delete ${hometeam_name} vs ${awayteam_name}`}
          />
        </div>

      <div className={styles.match__body}>
        <div className={styles.match__contestant}>
          <img
            className={styles.logo}
            src={hometeam_emblem}
            alt={hometeam_name}
          />
          <span className={styles.name}>{hometeam_name}</span>
        </div>

        <div className={styles.match__playtime}>
          <p className={styles.time}>{time}</p>
          <p className={styles.date}>{date}</p>
          <p className={styles.date}>{matchStatus}</p>
        </div>

        <div className={styles.match__contestant}>
          <img
            className={styles.logo}
            src={awayteam_emblem}
            alt={awayteam_name}
          />
          <span className={styles.name}>{awayteam_name}</span>
        </div>
      </div>
      {/* <button
        className={`${styles.match__remove__button}`}
        onClick={handleUpdate}
      >
        {loading ? (
          <svg
            className={`spin`}
            stroke="currentColor"
            fill="none"
            strokeWidth={0}
            viewBox="0 0 24 24"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 22c5.523 0 10-4.477 10-10h-3a7 7 0 01-7 7v3zM2 12C2 6.477 6.477 2 12 2v3a7 7 0 00-7 7H2z"
              fill="#000"
              stroke="none"
            />
          </svg>
        ) : (
          "Update Match"
        )}
      </button> */}
    </div>
  );
}

export default MobileView;
