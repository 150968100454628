import React, { useState, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import styles from "./how.module.css";
import video from "../../assets/images/video.png";
import useGetData from "hooks/useGetData";
import toast from "react-hot-toast";
import patchData from "utils/patchData";
import Preview from "./preview";
import TextEditor from "components/TextEditor";

interface SetHowToPlayProps {}

const SetHowToPlay: React.FC<SetHowToPlayProps> = () => {
  const [formInputData, setFormInputData] = useState({
    title: "",
    name: "",
    text: "",
  });
  const [preview, setPreview] = useState(false);
  const [loading, setLoading] = useState(false);
  const [videoFile, setVideoFile] = useState<File>();
  const { data } = useGetData<any>("/dashboard/how-to-play/2");

  useEffect(() => {
    // Check if data is truthy before accessing its properties
    if (data && data.data) {
      const { title, name, text } = data.data;
      setFormInputData((prev) => ({ ...prev, title, name, text }));
    }
  }, [data]);

  const handleSelection = async () => {
    if (!loading) {
      setLoading(true);
      const formData = new FormData();
      formData.append("title", formInputData.title);
      formData.append("name", formInputData.name);
      formData.append("text", formInputData.text);
      formData.append("video", videoFile as any);
      const { success, data } = await patchData(
        "/admin/update-how-to-play/2",
        {
          title: formInputData.title,
          name: formInputData.name,
          text: formInputData.text,
          video: videoFile as any,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (!success) {
        toast.error(data);
      } else {
        toast.success("Success:", data);
      }
    }
  };

  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      const file = acceptedFiles[0];

      if (file.type.startsWith("video/")) {
        setVideoFile(file);
      } else {
        toast.error("Please upload a valid video file (e.g., MP4).");
      }
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "video/*" as any,
  });

  const SwitchPage = () => {
    setPreview(!preview);
  };
  return (
    <>
      {preview ? (
        <Preview
          formInputData={formInputData}
          videoFile={videoFile}
          switchPage={SwitchPage}
          handleSelection={handleSelection}
        />
      ) : (
        <div>
          <div className={styles.input__container}>
            <div className={styles.input__box}>
              <input
                type="text"
                className={styles.input__field}
                placeholder="Title"
                value={formInputData.title}
                onChange={(e) =>
                  setFormInputData((prev) => ({
                    ...prev,
                    title: e.target.value,
                  }))
                }
              />
              <input
                type="text"
                className={styles.input__field}
                placeholder="Your Name"
                value={formInputData.name}
                onChange={(e) =>
                  setFormInputData((prev) => ({
                    ...prev,
                    name: e.target.value,
                  }))
                }
              />
            </div>
            <div
              {...getRootProps({
                className: styles.video__container,
              })}
            >
              <input {...getInputProps()} />
              {videoFile ? (
                <>
                  <p>{videoFile.name}</p>
                </>
              ) : (
                <>
                  <img src={video} alt="video tag" />
                  <h4>
                    Drag and Drop video Or{" "}
                    <span style={{ color: "#008F8F" }}>Browse</span>
                  </h4>
                </>
              )}
            </div>
          </div>
          <div className={styles.textarea__container}>
            <TextEditor 
              initialValue={formInputData.text}
              onChange={(value) => setFormInputData((prev) => ({ ...prev, text: value }))}
            />
            {/* <textarea
              placeholder="Type here..."
              value={formInputData.text}
              onChange={(e) =>
                
              }
            /> */}
          </div>
          <div className={styles.button__container}>
            <button className={styles.upload} onClick={handleSelection}>
              Upload
            </button>
            <button className={styles.preview} onClick={SwitchPage}>
              Preview
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default SetHowToPlay;
