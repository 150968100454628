
function FootballIcon(props:any) {
  return (
    <svg
      width={25}
      height={24}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.46 6.11a9.85 9.85 0 00-4.3-3.36A10 10 0 002.39 12v.56A9.94 9.94 0 003.72 17a10 10 0 005.89 4.65c.907.245 1.842.363 2.78.35a10 10 0 009.88-8.59c.076-.466.116-.938.12-1.41a9.83 9.83 0 00-1.93-5.89zm-2 .77l-1.07 2.86-1.62.44-2.38-1.69V6.64l2.49-1.81a7.81 7.81 0 012.62 2.05h-.04zm-4.07 4.79L13.61 14h-2.45l-.77-2.33 2-1.43 2 1.43zm-2-7.67c.372.004.743.034 1.11.09l-1.11.8-1.11-.8A8 8 0 0112.39 4zM5.27 8.37l.4 1.32-1.13.79a7.88 7.88 0 01.73-2.11zm1.37 9.17l1.38.05.37 1.33a8.322 8.322 0 01-1.75-1.38zm1.75-1.94l-3.15-.11A7.83 7.83 0 014.46 13l2.49-1.74 1.44.62.89 2.76-.89.96zm.86-5.53l-1.56-.7-.91-3a7.93 7.93 0 012.11-1.54l2.5 1.81v1.85l-2.14 1.58zm4.14 9.86a8.082 8.082 0 01-2.63-.12l-.83-2.92.83-.89h3.07l.67 1-1.11 2.93zm2.41-.7l.46-1.23 1.36.07a7.83 7.83 0 01-1.85 1.16h.03zm3.46-3.12L16.15 16l-.71-1.1.89-2.76 1.51-.41 2.36 2a7.839 7.839 0 01-.97 2.38h.03zm.05-5.83L19.79 9a7.4 7.4 0 01.53 2.13l-1.01-.85z"
        fill="#007575"
      />
    </svg>
  )
}

export default FootballIcon
