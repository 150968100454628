
function FolderIcon(props:any) {
  return (
    <svg
      width={32}
      height={26}
      viewBox="0 0 32 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M30.375 4.656H16.352L11.77.273a.318.318 0 00-.215-.086h-9.93c-.691 0-1.25.56-1.25 1.25v23.125c0 .692.559 1.25 1.25 1.25h28.75c.691 0 1.25-.558 1.25-1.25V5.907c0-.691-.559-1.25-1.25-1.25zM20.687 15.54c0 .149-.132.274-.293.274h-3.3v3.316a.278.278 0 01-.274.277h-1.64a.278.278 0 01-.274-.277v-3.317h-3.3c-.16 0-.293-.124-.293-.273V13.9c0-.149.132-.274.292-.274h3.301v-3.316c0-.153.125-.278.274-.278h1.64c.149 0 .274.125.274.278v3.316h3.3c.16 0 .294.125.294.273v1.641z"
        fill="#008F8F"
      />
    </svg>
  )
}

export default FolderIcon
