import styles from "./desktopview.module.css";
import { SingleMatchProps } from ".";

function DesktopView({
  hometeam_name,
  hometeam_emblem,
  awayteam_name,
  awayteam_emblem,
  date,
  time,
  loading,
  isSelected,
  handleRemove = () => null,
  handleSelection,
  remove_button,
}: SingleMatchProps) {
  return (
    <div className={styles.match__card}>
      <div className={styles.match__serial__no}></div>

      <div className="">
        <div className={styles.match__teams}>
          <div className={styles.match__contestant}>
            <img
              className={styles.logo}
              src={hometeam_emblem}
              alt={hometeam_name}
            />
            <span className={styles.name}>{hometeam_name}</span>
          </div>

          <div className="">
            <p className={styles.versus}>Vs</p>
          </div>

          <div className={styles.match__contestant}>
            <img
              className={styles.logo}
              src={awayteam_emblem}
              alt={awayteam_name}
            />
            <span className={styles.name}>{awayteam_name}</span>
          </div>
        </div>
      </div>

      <div className="">{time}</div>
      <div className="">{date}</div>
      <div className={styles.match__action__group}>
        {remove_button ? (
          <button
            className={`${styles.match__remove__button}`}
            onClick={handleRemove}
          >
            Remove Match
          </button>
        ) : (
          <button
            className={`${styles.match__select__button} ${
              isSelected && styles.selected
            }`}
            onClick={handleSelection}
          >
            {loading ? (
              <svg
                className={`spin`}
                stroke="currentColor"
                fill="none"
                strokeWidth={0}
                viewBox="0 0 24 24"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 22c5.523 0 10-4.477 10-10h-3a7 7 0 01-7 7v3zM2 12C2 6.477 6.477 2 12 2v3a7 7 0 00-7 7H2z"
                  fill="#fff"
                  stroke="none"
                />
              </svg>
            ) : isSelected ? (
              "Unselect"
            ) : (
              "Select"
            )}
          </button>
        )}
      </div>
    </div>
  );
}

export default DesktopView;
