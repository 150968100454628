import useCheckMobile from "hooks/useCheckMobile";
import { useState } from "react";
import toast from "react-hot-toast";
import postData from "utils/postData";
import DesktopView from "./DesktopView";
import MobileView from "./MobileView";
import moment from "moment";
import patchData from "utils/patchData";


export type SingleMatchProps = {
  match_id: number;
  matchStatus: string;
  hometeam_name: string;
  hometeam_emblem: string;
  awayteam_name: string;
  awayteam_emblem: string;
  league_year?: string;
  date: string;
  time: string;
  remove_button?: Boolean;
  handleRemove?: () => void
  loading?: Boolean;
  isSelected?: Boolean;
  setSelectedMatches?: React.Dispatch<any>;
  handleSelection?: () => void;
  updatePrediction?: (matchId: number) => Promise<void>;
};

function SingleMatch(props: SingleMatchProps) {
  const { isMobile } = useCheckMobile();

  const [loading, setLoading] = useState(false);

  async function updatePrediction(matchId: number) {
    setLoading(true);
    try {
      const response = await patchData(
        `/prediction/update-prediction/${matchId}`,
        {}
      );
  
      if (response.success) {
        toast.success(response.data);
      } else {
        toast.error(response.data);
      }
    } catch (error) {
      console.error('Error updating prediction:', error);
      toast.error('An error occurred while updating prediction.');
    }
    finally{
      setLoading(false);
    }
  }

  const handleSelection = async () => {
    if (!loading) {
      setLoading(true);
      const { success, data } = await postData(
        `/admin/select-match/${props.match_id}`,
        {
          season: props.league_year,
          gameWeek: `${moment().week()}`,
        }
      );

      if (!success) {
        toast.error(data);
        if(typeof data === "string"){
          props.setSelectedMatches && props?.setSelectedMatches((n: string[]) => [...n, props.match_id]);
        }

        setLoading(false);
        return;
      }

      props.setSelectedMatches && props?.setSelectedMatches((n: string[]) => [...n, data.matchId]);
      toast.success(`Match ${data.matchId} has been selected.`);
      setLoading(false);
    }
  };

  return isMobile ? (
    <MobileView
      {...props}
      loading={loading}
      handleSelection={handleSelection}
      updatePrediction={updatePrediction}
    />
  ) : (
    <DesktopView
      {...props}
      loading={loading}
      handleSelection={handleSelection}
      updatePrediction={updatePrediction}
    />
  );
}

export default SingleMatch;
