import Input from "components/Input";
import { AuthUser } from "contexts/AuthUser";
import { Formik } from "formik";
import { useContext } from "react";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { setCookie } from "typescript-cookie";
import { setLocalUser } from "utils/TokenService";
import postData from "utils/postData";
import login_schema, { LoginType } from "validations/login.schema";
import styles from "./login.module.css";

export default function Login() {
  const navigate = useNavigate();
  const { setUser } = useContext(AuthUser);

  const formikData = {
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: async (values: LoginType, { resetForm }: any) => {
      const data = await postData("/admin/login", values);

      if (data.success) {
        resetForm();
        setUser(data.data);

        setCookie("accessToken", data?.accessToken);
        setCookie("refreshToken", data?.refreshToken);

        setLocalUser(data.data);

        toast.success("Admin Login Success");
        navigate("/overview");

        return;
      }

      toast.error(data.data || "Error logging in");
    },
    validationSchema: login_schema,
  };

  return (
    <Formik {...formikData}>
      {({ handleSubmit, isSubmitting }: any) => (
        <form className={styles.login__form} onSubmit={handleSubmit}>
          <div className="">
            <h1 className={styles.form__heading}>Welcome to 3Scorers!</h1>
            <p className={styles.form__subtitle}>Login into your account.</p>
          </div>

          <div className={styles.form__input__group}>
            <Input
              type="email"
              name="email"
              label="Email Address"
              placeholder="Enter Email Address"
            />

            <Input
              type="password"
              name="password"
              label="Password"
              placeholder="Enter Password"
              is_password_field
            />

            <div className={`flex justify-between items-center`}>
              <div className="flex items-center gap-0.5">
                <input
                  id="remeber_me"
                  className={styles.form__checkbox}
                  name="remember_me"
                  type="checkbox"
                />
                <label
                  className={styles.form__remember__me}
                  htmlFor="remeber_me"
                >
                  Remember me
                </label>
              </div>
              <Link className={styles.forgot__password} to="/forgot-password">
                Forgot Password?
              </Link>
            </div>
          </div>

          <div className="">
            <button
              type="submit"
              className={styles.form__primary__button}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <svg
                  className={`spin`}
                  stroke="currentColor"
                  fill="none"
                  strokeWidth={0}
                  viewBox="0 0 24 24"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 22c5.523 0 10-4.477 10-10h-3a7 7 0 01-7 7v3zM2 12C2 6.477 6.477 2 12 2v3a7 7 0 00-7 7H2z"
                    fill="#fff"
                    stroke="none"
                  />
                </svg>
              ) : (
                "Login"
              )}
            </button>
          </div>

          {/* <div className={`text-center`}>
            <p className="">
              Don't have an account?&nbsp;
              <Link className={`color-primary-50`} to="/sign-up">
                Sign Up here
              </Link>
            </p>
          </div> */}
        </form>
      )}
    </Formik>
  );
}
