import { ReactPropTypes } from "react"

type PropType = {
    unread?: Boolean,
    props?: ReactPropTypes
}

function NotificationBell({unread, ...props}:PropType) {
  return (
    <>
      <svg
        className="desktop-bell"
        xmlns="http://www.w3.org/2000/svg"
        width={25}
        height={24}
        viewBox="0 0 25 24"
        fill="none"
        {...props}
      >
        <path
          d="M22.29 18.754l-.226-.2A9.578 9.578 0 0120.39 16.6a8.419 8.419 0 01-.9-3.206V10.1a7.207 7.207 0 00-6.294-7.166v-.86a.89.89 0 10-1.78 0v.873a7.207 7.207 0 00-6.22 7.153v3.294a8.42 8.42 0 01-.9 3.206 9.6 9.6 0 01-1.646 1.954l-.227.2v1.88H22.29v-1.88zM10.604 21.334a1.767 1.767 0 003.5 0h-3.5z"
          fill="#008F8F"
        />
        {unread && <circle cx={18.3906} cy={8} r={4} fill="red" />}
      </svg>

      <svg
        className="mobile-bell"
        xmlns="http://www.w3.org/2000/svg"
        width={25}
        height={25}
        viewBox="0 0 25 25"
        fill="none"
        {...props}
      >
        <path
          d="M20.335 17.12c0 .85-.69 1.54-1.54 1.54H5.435a1.54 1.54 0 010-3.08h.51v-5.14c0-3.4 2.76-6.17 6.17-6.17 1.7 0 3.24.69 4.36 1.81a6.11 6.11 0 011.81 4.36v5.14h.51c.85 0 1.54.69 1.54 1.54zM12.115 4.28V3.25M15.195 18.67c0 1.71-1.38 3.08-3.08 3.08-1.7 0-3.08-1.38-3.08-3.08h6.16z"
          stroke="#fff"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
          />
          {unread && <circle cx={18.3906} cy={8} r={4} fill="red" />}
        </svg>
    </>
  )
}

export default NotificationBell
