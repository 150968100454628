import useGetData from "hooks/useGetData";
import SingleMatch from "./SIngleMatch";
import styles from "./matches.module.css";

import Select from "components/Select";
import moment from "moment-timezone";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import getData from "utils/getData";
import Filter from "./Filter";
import { League, LeaguesApiResponse, Match, Prediction } from "./types";
import deleteData from "utils/deleteData";

export default function MatchdayLive({
  selectedMatches,
  setSelectedMatches,
  predictions,
  setPredictions,
}: any) {
  const { data: leaguesData } = useGetData<LeaguesApiResponse>(
    "/football/get-leagues"
  );
  const leagues = leaguesData?.data || [];

  const [matches, setMatches] = useState<Match[] | []>([]);

  const [filters, setFilters]: any = useState({
    search: "",
    league: "",
  });

  const refreshMatches = () => {
    const default_league = `152`;
    
    const getLeagueMatches = async () => {
      const response = await getData(
        "/football/get-weekly-matches-by-league-id",
        {
          params: {
            leagueId: filters.league || default_league,
          },
        }
      );

      if (response.success) {
        return setMatches(response?.data);
      }

      toast.error(response?.data);
    };

    const league_name = leagues.find(
      ({ league_id }: League) => league_id === (filters.league || default_league)
    )?.league_name;

    toast.promise(new Promise((resolve) => resolve(getLeagueMatches())), {
      loading: `Fetching ${league_name} matches`,
      error: `Error fetching ${league_name} matches`,
      success: `Matches updated`,
    });
  };

  const handleRemove = (id: string) => {
    const removePrediction = async () => {
      const response = await deleteData(`/prediction/delete-prediction/${id}`);

      if (response.success) {
        setPredictions((n: Prediction[]) =>
          n.filter((item) => item.matchId !== id)
        );
      }

      setSelectedMatches((n: string[]) => n.filter((item) => item !== id));
    };

    toast.promise(new Promise((resolve) => resolve(removePrediction())), {
      loading: `Removing selection`,
      error: `Error removing selection`,
      success: `Selection removed`,
    });
  };

  useEffect(() => {
    refreshMatches();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.league]);

  useEffect(() => {
    localStorage.setItem("season", leagues.find(l => l.league_id === '152')?.league_season || "2023/2024")
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.table}>
      <div className={styles.table__head}>
        <p className={styles.table__description}>
          Select 5 matches from any of the leagues
        </p>
        <Select
          defaultValue={"Choose League"}
          options={leagues.map(
            ({ league_id, league_name, league_logo }: League) => {
              return {
                icon: league_logo,
                value: league_id,
                placeholder: league_name,
              };
            }
          )}
          onChange={(value) => setFilters({ ...filters, league: value })}
        />
      </div>

      <div className={styles.table__filter__mobile}>
        <Filter
          options={leagues?.map(
            ({ league_id, league_name, league_logo }: League) => {
              return {
                icon: league_logo,
                value: league_id,
                placeholder: league_name,
              };
            }
          )}
          onOptionChange={(value) => setFilters({ ...filters, league: value })}
          onSearchChange={(value) => setFilters({ ...filters, search: value })}
          optionsTitle="Select League:"
          searchPlaceholder="Search Club"
        />
      </div>

      <div className={styles.table__body}>
        <div className={`${styles.table__row} ${styles.table__header}`}>
          <div className=""></div>
          <div className={styles.table__column__title}>Matches</div>
          <div className={styles.table__column__title}>Time</div>
          <div className={styles.table__column__title}>Date</div>
          <div className={styles.gameWeek}>Match Week {moment().week()}</div>
        </div>

        {matches?.map((match: Match) => {
          let client_tz = moment.tz.guess(true);
          let m = moment.tz(
            `${match.match_date} ${match.match_time}`,
            process.env.REACT_APP_TIMEZONE as string
          );
          let match_props = {
            match_id: match.match_id,
            hometeam_emblem: match.team_home_badge,
            awayteam_emblem: match.team_away_badge,
            hometeam_name: match.match_hometeam_name,
            awayteam_name: match.match_awayteam_name,
            league_year: match.league_year,
            date: m.tz(client_tz).format("MMMM DD, YYYY"),
            time: m.tz(client_tz).format("h:mma"),
          };

          return (
            <SingleMatch
              key={match.match_id}
              isSelected={predictions?.[moment().week()]?.find(
                (item: Prediction) => item.matchId === match.match_id
              )}
              setSelectedMatches={setSelectedMatches}
              handleRemove={() => handleRemove(match.match_id)}
              {...match_props}
            />
          );
        })}
      </div>
    </div>
  );
}
