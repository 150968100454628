import SingleUserList from "components/lists/SingleUserList";
import { User } from "pages/Overview/types";
import styles from "./users-card.module.css";

type UsersCardProps = {
  title: string;
  users?: User[];
};

function OverviewUsersCard({ title, users }: UsersCardProps) {

  return (
    <div className={`${styles.users__card} space-y-2 w-full`}>
      <div className={styles.users__card__header}>
        <h1 className="">{title}</h1>
      </div>

      <div className={`${styles.users__card__body} space-y-1.5`}>
        {users?.map(({ id, image, firstName, lastName }: User) => (
          <SingleUserList
            key={id}
            id={id}
            firstName={firstName}
            lastName={lastName}
            image={image}
          />
        ))}
      </div>
    </div>
  );
}

export default OverviewUsersCard;
