import VideoIcon from "components/icons/VideoIcon";
import moment from "moment";
import { useRef, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import postData from "utils/postData";
import AdCard from "./AdCard";
import styles from "./advertisments.module.css";
import { Advert } from "./types";

function CreateAdvert() {
  const navigate = useNavigate();
  const fileRef = useRef<HTMLInputElement>(null);

  const initialData = {
    companyName: "",
    endDate: "",
    file: "",
  }
  
  const [formData, setFormData] = useState<Advert>(initialData);
  const [filetype, setFileType] = useState<"image" | "video" | undefined>(
    undefined
  );
  const [isDragOver, setIsDragOver] = useState(false);

  const handleFileUpload = async (files: FileList | null) => {
    const [file]: [File] = files as any;

    if (files && files.length > 1) {
      toast.error("Max file limit of 1 exceeded");
      return;
    }

    if (!new RegExp(/^image\/.*$|^video\/.*$/).test(file.type)) {
      toast.error("Image or videos allowed only");
      return;
    }

    setFileType(
      new RegExp(/^image\/.*$/).test(file.type)
        ? "image"
        : new RegExp(/^video\/.*$/).test(file.type)
        ? "video"
        : undefined
    );

    setFormData((n) => ({ ...n, file }));
  };

  const onDrop = (e: any) => {
    e.preventDefault();
    handleFileUpload(e.dataTransfer.files);
    setIsDragOver(false);
  };

  const publishAd = async () => {
    if (!formData?.companyName) {
      return toast.error("Company Name cannot be empty");
    }

    if (!formData?.endDate) {
      return toast.error("End Date cannot be empty");
    }

    if (!formData?.file) {
      return toast.error("Advert Image or Video cannot be empty");
    }

    const postAdvert = async () => {
      const { success, data } = await postData(
        "/admin/create-advertisement",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
  
      if (!success) {
        toast.error(data);
        return;
      }

      setFormData(initialData);
      setFileType(undefined);
    }

    toast.promise(postAdvert(), {
      loading: "Publising advert...",
      success: "Advert published successfully",
      error: "Error publishing advert"
    })

  };

  return (
    <div className={`${styles.container} space-y-1`}>
      <div className={`${styles.wrapper} space-y-2`}>
        {/* Input fields Section */}

        <form className={styles.form__fields}>
          <div className={"space-y-1"}>
            <input
              className={styles.input__field}
              type="text"
              placeholder="Company Name"
              value={formData?.companyName}
              onChange={(e) => setFormData(n => ({...n, companyName: e.target.value }))}
            />

            <input
              className={styles.input__field}
              type="date"
              placeholder="End Date"
              value={formData?.endDate}
              min={moment(Date.now()).format("YYYY-MM-DD")}
              onChange={(e) => setFormData(n => ({...n, endDate: e.target.value }))}
            />

            {/* <div className={styles.flex__fields}>
              <div className="">
                <input
                  className={styles.input__field}
                  type="text"
                  placeholder="Start Date"
                  value={formData.startDate}
                  />
                </div>
            </div> */}
          </div>

          <div className={styles.drop__zone}>
            <div className={isDragOver ? styles.drop__overlay : ""}></div>
            <div
              className={styles.upload__field}
              onClick={() => fileRef?.current?.click()}
              onDrop={onDrop}
              onDragOver={(e) => {
                e.preventDefault();
                setIsDragOver(true);
              }}
              onDragLeave={(e) => {
                e.preventDefault();
                setIsDragOver(false);
              }}
            >
              <input
                ref={fileRef}
                hidden
                type="file"
                onChange={(e) => handleFileUpload(e.target.files)}
                accept="image/*,video/*"
              />

              {typeof formData?.file !== "string" ? 
              <p className={styles.uploaded__text}>
                <span>{formData?.file?.name as any} ({ (formData?.file?.size / (2**20)).toFixed(2) }MiB)</span>
                <span className="">Reupload</span>
              </p> :
              (  
              <>
                <VideoIcon />
                <p className={styles.upload__text}>
                  Drag and Drop picture or video Or <span>Browse</span>
                </p>
              </>
              )}
            </div>
          </div>
        </form>
        <hr className={styles.hr} />

        {/* Preview Section */}
        <div className={styles.preview__section}>
          <p className={styles.preview__section__heading}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={10}
              height={13}
              viewBox="0 0 15 18"
              fill="none"
            >
              <path
                d="M15 9L-8.15666e-07 17.6603L-5.85621e-08 0.339745L15 9Z"
                fill="#303030"
              />
            </svg>
            Preview
          </p>

          <div className={styles.ads__wrapper}>
            <AdCard
              companyName={formData?.companyName}
              endDate={formData?.endDate}
              file={formData?.file ? URL.createObjectURL(formData?.file as Blob) : ""}
              filetype={filetype}
            />
          </div>
        </div>
        <hr className={styles.hr} />

        {/* Buttons Section */}
        <div
          className={`${styles.buttons_group} ${styles.create__buttons} justify-center`}
        >
          <button
            className={`${styles.button} ${styles.disabled_button}`}
            onClick={() => navigate(-1)}
          >
            Go Back
          </button>
          <button
            className={`${styles.button} ${styles.active_button}`}
            onClick={publishAd}
          >
            Upload
          </button>
        </div>
      </div>
    </div>
  );
}

export default CreateAdvert;
