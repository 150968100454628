import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getLocalRefreshToken } from "utils/TokenService";
import toast from "react-hot-toast"
import getData from "utils/getData";
import { AxiosRequestConfig } from "axios";

export default function useGetData<Type>(url: string | string[], opts?: AxiosRequestConfig) {
  const [data, setData] = useState<Type | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const get_data = async () => {
      if(Array.isArray(url)){
        let reqs = url.map(async(u) => await getData(u, {...opts}));

        Promise.all(reqs)
        .then((res) => {

          if(!res && !getLocalRefreshToken()){
            toast.error("Session expired! Re-authenticate");
            return navigate('/');
          }

          setData(res as any);
        })
        .catch(err => console.error(err));

        return;
      }

      const resp = await getData(url, {...opts});

      if(!resp && !getLocalRefreshToken()){
        toast.error("Session expired! Re-authenticate");
        return navigate('/');
      }

      if(!resp.success){
        toast.error(resp.data);
        return;
      }

      setData(resp);
    };

    get_data();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ ]);

  return { data, setData };
}
