import useDocumentTitle from "hooks/useDocumentTitle";
import useGetData from "hooks/useGetData";
import { useState } from "react";
import toast from "react-hot-toast";
import postData from "utils/postData";
import styles from "./styles.module.css";

type NotificationTypes = {
  title: string;
  body: string;
  email: string;
  isGeneral: boolean;
};

function SendNotifications() {
  const initialData = {
    title: "",
    body: "",
    email: "",
    isGeneral: true,
  };

  const { title } = useDocumentTitle();
  const { data } = useGetData<any>(
    "/admin/get-users-and-profile-images?perPage=1000"
  );
  const users = data?.data;

  const [formData, setFormData] = useState<NotificationTypes>(initialData);

  const handleCreate = () => {
    const url = formData.isGeneral
      ? "/notification/general-notification"
      : "/notification/individual-notification";

    const reqBody = formData.isGeneral
      ? { title: formData.title, body: formData.body }
      : {
          title: formData.title,
          body: formData.body,
          email: formData.email,
        };

    // type === "available-predictions"
    //   ? "/admin/create-prediction-available"
    //   : type === "deadline-predictions"
    //   ? "/admin/create-prediction-deadline"
    //   : type === "status-predictions"
    //   ? "/admin/create-prediction-status"
    //   : "";

    if (!url) return toast.error("Invalid API url");

    toast.promise(postData(url, reqBody), {
      loading: `Creating notification...`,
      success: `Notification created successfully.`,
      error: `Error creating notification.`,
    });
  };

  return (
    <div className={`${styles.content} space-y-2`}>
      <h2 className="">{title}</h2>
      <div className={`${styles.wrapper} space-y-2`}>
        {/* Input fields Section */}

        <form className={styles.form__fields}>
          <div className={"space-y-1"}>
            <input
              className={styles.input__field}
              type="text"
              placeholder="Title"
              value={formData?.title}
              onChange={(e) =>
                setFormData((n) => ({ ...n, title: e.target.value }))
              }
            />

            <input
              className={styles.input__field}
              type="text"
              placeholder="Body"
              value={formData?.body}
              onChange={(e) =>
                setFormData((n) => ({ ...n, body: e.target.value }))
              }
            />

            <div className={styles.form_group}>
              <input
                className={styles.input__checkbox}
                type="checkbox"
                checked={!formData?.isGeneral}
                onChange={(e) =>
                  setFormData((n) => ({ ...n, isGeneral: !e.target.checked }))
                }
              />
              <strong>Send notification to a user?</strong>
            </div>

            {!formData.isGeneral && (
              <>
                <input
                  list="users"
                  className={styles.input__field}
                  type="text"
                  placeholder="User Email"
                  value={formData?.email}
                  onChange={(e) =>
                    setFormData((n) => ({ ...n, email: e.target.value }))
                  }
                />
                <datalist id="users">
                  {users.map(({ id, email }: any) => (
                    <option key={id} value={email} />
                  ))}
                </datalist>

                {/* // <div className="">
                  
                // </div> */}
                {/* <div className="">
                  
                  <div className={`flex ${styles.user__option}`}>
                    <img src=""></img>
                    <div className="">
                      <strong>Customer name</strong><br />
                      <small>Customer name</small>
                    </div>
                  </div>
                  
                </div> */}
              </>
            )}
          </div>
          <div className={"space-y-1"}></div>
        </form>
        <hr className={styles.hr} />

        {/* Preview Section */}
        <div className={styles.preview__section}>
          <p className={styles.preview__section__heading}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={10}
              height={13}
              viewBox="0 0 15 18"
              fill="none"
            >
              <path
                d="M15 9L-8.15666e-07 17.6603L-5.85621e-08 0.339745L15 9Z"
                fill="#303030"
              />
            </svg>
            Preview
          </p>

          <div className={styles.phone}>
            <img src="/phone-bg2.webp" alt="" />
            <div className={styles.phone__overlay}></div>
            <div className={styles.notification}>
              <img src="/logo.jpg" alt="" />
              <div className={styles.text}>
                <strong className="">{formData.title}</strong>
                <p className="">{formData.body}</p>
              </div>
            </div>
          </div>
        </div>
        <hr className={styles.hr} />

        {/* Buttons Section */}
        <div
          className={`${styles.buttons_group} ${styles.create__buttons} justify-center`}
        >
          {/* <button
            className={`${styles.button} ${styles.disabled_button}`}
            onClick={() => navigate(-1)}
          >
            Go Back
          </button> */}
          <button
            className={`${styles.button} ${styles.active_button}`}
            onClick={handleCreate}
          >
            Upload
          </button>
        </div>
      </div>
    </div>
  );
}

export default SendNotifications;
