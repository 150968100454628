import { JSXElement } from "@babel/types";
import { ReactNode, useEffect, useState } from "react";
import styles from "./filter-options.module.css";

type SingleSelectedValue = string | number;
type MultipleSelectedValues = (string | number)[] | [];

type FilterProps = {
  title?: string | JSXElement;
  options?: {
    icon: string;
    value: string | number;
    placeholder: string | JSX.IntrinsicElements | ReactNode;
  }[];
  onChange?: (value: SingleSelectedValue | MultipleSelectedValues) => void;
  open: Boolean;
  closeFn: Function;
  allowMultipleSelections?: Boolean;
};

function FilterOptions({
  title,
  options,
  onChange,
  open,
  closeFn,
  allowMultipleSelections,
}: FilterProps) {
  // State for Single Selection
  const [selectedValue, setSelectedValue] = useState<SingleSelectedValue>("");
  // State for Multiple Selection
  const [selectedValues, setSelectedValues] = useState<MultipleSelectedValues>(
    []
  );
  const [showModal, setShowModal] = useState<Boolean>(open);
  const [search, setSearch] = useState<string>("");

  useEffect(() => {
    if (onChange) {
      onChange(allowMultipleSelections ? selectedValues : selectedValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue, selectedValues]);

  useEffect(() => {
    setShowModal(open);
  }, [open]);

  useEffect(() => {
    if (!showModal) {
      closeFn();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  return (
    <>
      {showModal && (
        <div
          className={styles.overlay}
          onClick={() => setShowModal(false)}
        ></div>
      )}
      <div
        className={`${styles.filter} ${!showModal ? styles.filter__hide : ""}`}
      >
        <div className={styles.filter__head}>
          <div className={styles.drag}>
            <div className={styles.drag__handle}></div>
          </div>
          <div className={styles.filter__head__title}>
            <span className="">{title as ReactNode}</span>
          </div>
        </div>

        <div className={styles.filter__body}>
          <div className={styles.filter__search__container}>
            <input
              className={styles.filter__search__input}
              type="text"
              placeholder="Search League"
              id="search__bar"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setSearch(e.target.value)
              }
              value={search}
            />
          </div>
          {options
            ?.filter(({ placeholder }: any) =>
              new RegExp(search.toLowerCase()).test(placeholder?.toLowerCase())
            )
            .map(({ icon, value, placeholder }, index) => (
              <div
                key={index}
                className={styles.filter__option}
                onClick={() =>
                  allowMultipleSelections
                    ? setSelectedValues(
                        (n): MultipleSelectedValues => [...n, value]
                      )
                    : setSelectedValue(value)
                }
              >
                <input
                  type="radio"
                  className={styles.filter__option__radio}
                  checked={
                    allowMultipleSelections
                      ? selectedValues.includes(value as never)
                      : selectedValue === value
                  }
                  onChange={() => null}
                />

                <div className={styles.filter__option__details}>
                  {icon ? (
                    <img
                      className={styles.icon}
                      src={icon}
                      alt=""
                      loading="lazy"
                      onError={(e) =>
                        (e.currentTarget.src =
                          "data:image/svg+xml,%3Csvg stroke='currentColor' fill='currentColor' stroke-width='0' viewBox='0 0 16 16' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.775 11.775 0 0 1-2.517 2.453 7.159 7.159 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7.158 7.158 0 0 1-1.048-.625 11.777 11.777 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 62.456 62.456 0 0 1 5.072.56z'%3E%3C/path%3E%3C/svg%3E")
                      }
                    />
                  ) : (
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth={0}
                      viewBox="0 0 16 16"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 011.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.775 11.775 0 01-2.517 2.453 7.159 7.159 0 01-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7.158 7.158 0 01-1.048-.625 11.777 11.777 0 01-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 012.185 1.43 62.456 62.456 0 015.072.56z"
                        stroke="none"
                      />
                    </svg>
                  )}
                  <span className="">{placeholder as ReactNode}</span>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
}

export default FilterOptions;
