
function UsersIcon(props:any) {
  return (
    <svg
      width={25}
      height={24}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.69 12.22a4.92 4.92 0 001.7-3.72 5 5 0 00-10 0 4.92 4.92 0 001.7 3.72 8 8 0 00-4.7 7.28 1 1 0 102 0 6 6 0 0112 0 1 1 0 002 0 8 8 0 00-4.7-7.28zm-3.3-.72a3 3 0 110-6 3 3 0 010 6zm9.74.32a5 5 0 00-3.74-8.32 1 1 0 000 2 3 3 0 013 3 3 3 0 01-1.5 2.59 1 1 0 00-.05 1.7l.39.26.13.07a7 7 0 014 6.38 1 1 0 002 0 9 9 0 00-4.23-7.68z"
        fill="#004F4F"
      />
    </svg>
  )
}

export default UsersIcon
