import { ReactNode, createContext, useState } from "react";

export const CLContext = createContext<any>(null);

export function CLContextProvider({ children }: { children: ReactNode }) {
  const [show_modal, setShowModal] = useState<Boolean>(false);
  return (
    <CLContext.Provider value={{show_modal, setShowModal}}>{children}</CLContext.Provider>
  );
}
