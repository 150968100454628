import Tabs from "components/Tabs";
import React from "react";
import toast from "react-hot-toast";
import patchData from "utils/patchData";
import styles from "./rewards.module.css";
import { FormDataProps, Reward } from "./types";
import TextEditor from "components/TextEditor";

type Props = {
  setTogglePreview: React.Dispatch<React.SetStateAction<boolean>>;
  EnumIDs: any;
  rewards: Reward[] | undefined;
  formData: any;
  setFormData: React.Dispatch<any>;
  className: string,
};

function Main({
  setTogglePreview,
  formData,
  setFormData,
  EnumIDs,
  rewards,
  className,
}: Props) {
  const handleTextUpadte = (
    id: Reward["id"],
    type: Reward["type"],
    text: Reward["text"]
  ) => {
    setFormData((n: FormDataProps) => ({
      ...n,
      [id]: {
        text,
        type,
      },
    }));
  };

  const uploadRewards = () => {
    const reqs = EnumIDs.map(
      async (id: string | any) =>
        await patchData(`/admin/update-reward/${id}`, formData[id])
    );
    
    // const patchRewards = async() => {
    //   .then((resp: ApiResponse) => {
    //     resp?.forEach(({ data: { id, type, text } }) => {
    //       setFormData({
    //         [id]: {
    //           type,
    //           text,
    //         }
    //       })
    //     })
    //   })
    // }
    

    toast.promise(Promise.all(reqs), {
      loading: `Publishing Rewards`,
      success: `Rewards Published`,
      error: `Publishing failed`,
    });
  };

  return (
    <div className={`flex flex-col gap-2 ${className}`}>
      <Tabs
        headings={["Weekly", "Monthly", "Seasonal"]}
        contents={
          rewards?.map((reward) => (
            <div className="">
              <TextEditor 
                initialValue={formData?.[reward.id]?.text}
                onChange={(value) => handleTextUpadte(reward.id, reward.type, value)}
              />
              {/* <textarea
                className={styles.textarea}
                placeholder="Type here..."
                value={formData?.[reward.id]?.text}
                onChange={(e) =>
                  handleTextUpadte(reward.id, reward.type, e.target.value)
                }
              /> */}
            </div>
          )) as React.ReactNode[]
        }
        config={{
          tabHeaderCSS: styles.tab_header,
        }}
      />

      <div className={styles.buttons_group}>
        <button
          className={`${styles.button} ${styles.active_button}`}
          onClick={uploadRewards}
        >
          Upload
        </button>
        <button
          className={`${styles.button} ${styles.disabled_button}`}
          onClick={() => setTogglePreview(true)}
        >
          Preview
        </button>
      </div>
    </div>
  );
}

export default Main;
