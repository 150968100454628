import useGetData from "hooks/useGetData";
import { useLayoutEffect, useState } from "react";
import Main from "./Main";
import Preview from "./Preview";
import styles from "./rewards.module.css";
import { ApiResponse, FormDataProps, Reward, SingleReward } from "./types";


enum GameType {
    Weekly = 1,
    Monthly = 2,
    Seasonal = 3,
}
  
function SetRewards() {
  const [togglePreview, setTogglePreview] = useState(false);
  const [formData, setFormData] = useState<FormDataProps | {}>({});

  const EnumIDs = Object.values(GameType).filter((x) => typeof x === "number");
//   const EnumValues = Object.values(GameType).filter(
//     (x) => typeof x === "string"
//   );

  const { data } = useGetData<ApiResponse>(
    EnumIDs.map(
      (id: string | GameType) =>
        `/dashboard/reward/${id}?type=${GameType[id as number]}`
    )
  );

  const rewards: Reward[] | undefined = data?.map(
    (reward: SingleReward): Reward => reward.data
  );

  useLayoutEffect(() => {
    rewards?.forEach((reward) => {
      setFormData((n: any) => ({
        ...n,
        [reward.id]: {
          type: reward.type,
          text: reward.text,
        },
      }));
    });

    return;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div className={styles.container}>
        <Main 
            setTogglePreview={setTogglePreview}
            EnumIDs={EnumIDs}
            rewards={rewards}
            formData={formData}
            setFormData={setFormData}
            className={ togglePreview ? styles.hide__block : "" }
            />
        <Preview
            setTogglePreview={setTogglePreview} 
            formData={formData}
            className={ !togglePreview ? styles.hide__block : "" }
        />
    </div>
  );
}

export default SetRewards;
