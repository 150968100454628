import { useEffect, useState } from "react";

function useCheckMobile(mediaQuery: string = "(min-width: 1024px)") {
  const [isMobile, setIsMobile] = useState<Boolean>(false);

  const handleScreenResize = () => {
    setIsMobile(!window.matchMedia(mediaQuery).matches);
  };

  useEffect(() => {
    handleScreenResize();

    window.addEventListener("resize", handleScreenResize);

    return () => {
      window.removeEventListener("resize", handleScreenResize);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return { isMobile, setIsMobile };
}

export default useCheckMobile;
