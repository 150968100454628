import OverviewIcon from "components/icons/OverviewIcon";
import SettingsIcon from "components/icons/SettingsIcon";
import { NavLink } from "react-router-dom";
import styles from "./navbar.module.css";


const NavbarLinks = [
    { icon: OverviewIcon, title: "Overview", href: "/overview" },
    { icon: SettingsIcon, title: "Settings", href: "/settings" }
]

function Navbar() {
  return (
    <nav className={styles.navbar__box}>
        {NavbarLinks.map(({ icon: Icon, title, href }, index) => (
        <NavLink
            key={index}
            to={href}
            className={({ isActive }) => `
                ${styles.navbar__item} ${isActive ? styles.navbar__item__active : ""} 
            `}
        >
            <div className={styles.icon}>
                <Icon />
            </div>
            <span className="">{title}</span>
        </NavLink>
        ))}
        
        <NavLink
            to={"/profile"}
            className={({ isActive }) => `
                ${styles.navbar__item} ${isActive ? styles.navbar__item__active : ""} 
            `}
        >
            <div className={styles.icon}>
                <img src={`data:image/svg+xml,%3Csvg stroke='currentColor' fill='%23878787' stroke-width='0' viewBox='0 0 512 512' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M399 384.2C376.9 345.8 335.4 320 288 320H224c-47.4 0-88.9 25.8-111 64.2c35.2 39.2 86.2 63.8 143 63.8s107.8-24.7 143-63.8zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm256 16a72 72 0 1 0 0-144 72 72 0 1 0 0 144z'%3E%3C/path%3E%3C/svg%3E`} alt="Profile"/>
            </div>
            <span className="">My Profile</span>
        </NavLink>
    </nav>
  );
}

export default Navbar;
