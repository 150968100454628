import { CLContextProvider } from "contexts/CLContext";
import ConsoleLayout from "./ConsoleLayout";

function Index() {
  return (
    <CLContextProvider>
      <ConsoleLayout />
    </CLContextProvider>
  );
}

export default Index;
