import Tabs from "components/Tabs";
import OverviewUsersCard from "components/cards/OverviewUsersCard";
import SummaryCard from "components/cards/SummaryCard";
import FootballIcon from "components/icons/FootballIcon";
import IncreaseMoneyIcon from "components/icons/IncreaseMoneyIcon";
import UsersIcon from "components/icons/UsersIcon";
import useGetData from "hooks/useGetData";

import useCheckMobile from "hooks/useCheckMobile";
import { useEffect } from "react";
import getData from "utils/getData";
import style from "./overview.module.css";
import { User, UsersApiResponse } from "./types";

export interface Users {
  success: boolean;
  data: {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    username: string;
    gender: string;
    age: number;
    DOB: string;
    userId: number;
    nationality: string;
    updatedAt: string;
    createdAt: string;
  }[];
}

export default function Overview() {
  const { data, setData } = useGetData<UsersApiResponse>(
    "/admin/get-users-and-profile-images"
  );
  const users = data?.data.filter((user: User) => user.id !== 1);



  const { isMobile } = useCheckMobile();

  /*
   * The purpose of this useEffect is to get the total number of users,
   * but doing so returns all the users data and that might no be
   * the best optimizable approach.
   *
   * If there could be an added "totalCount" in the response that could be better
   */
  useEffect(() => {
    const getMoreUsers = async () => {
      const more_users = await getData("/admin/get-users-and-profile-images", {
        params: {
          page: data && data.currentPage + 1
        },
      });

      setData((n: any) => ({
        ...more_users,
        data: [...n.data, ...more_users.data],
      }));
    };

    if (data && data.currentPage < data.totalPages) {
      getMoreUsers();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.currentPage]);

  return (
    <div className={`${style.page__content} space-y-1`}>
      <div className={`${style.overview__section} space-y-1`}>
        <h1 className={style.section__title}>Overview Summary</h1>
        <div className={style.overview__card__list}>
          <SummaryCard
            label="Total Number of Users"
            value={users?.length}
            icon={<UsersIcon />}
          />

          <SummaryCard
            label="Total Number of Games Predicted"
            value={0}
            icon={<FootballIcon />}
            bg={"bg-primary-100"}
          />

          <SummaryCard
            label="Total Amount of Prizes Won"
            value={0}
            icon={<IncreaseMoneyIcon />}
            bg={"bg-secondary-100"}
          />
        </div>
      </div>

      <Tabs
        headings={["Users", "Admin"]}
        contents={[
          <OverviewUsersCard title={"List of Users"} users={users} />,
          <OverviewUsersCard title={"List of Top Winners"} users={users} />,
        ]}
        config={{
          tabHeaderVisible: isMobile,
          tabBodyCSS: style.users__card__section,
          tabHeaderCSS: style.users__card__tab__header,
        }}
      />
    </div>
  );
}
