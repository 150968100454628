import { AuthUserProvider } from "contexts/AuthUser";
import { Toaster } from "react-hot-toast";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import routes from "routes";
import "assets/styles/App.css";

const router = createBrowserRouter(routes);

function App() {
  return (
    <AuthUserProvider>
      <RouterProvider router={router} />
      <Toaster containerClassName="toast__styles" />
    </AuthUserProvider>
  );
}

export default App;
