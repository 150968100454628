import * as React from "react";

function CheckIcon(props: any) {
  return props.disabled ? (
    <svg
      width={30}
      height={30}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={15} cy={15.0029} r={15} fill="#BDBDBD" />
      <path
        d="M21.71 10.213a1 1 0 00-1.42 0l-7.45 7.46-3.13-3.14a1.022 1.022 0 00-1.42 1.47l3.84 3.84a1.001 1.001 0 001.42 0l8.16-8.16a1 1 0 000-1.47z"
        fill="#fff"
      />
    </svg>
  ) : (
    <svg
      width={30}
      height={30}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={15} cy={15.0029} r={15} fill="#008F8F" />
      <path
        d="M21.71 10.213a1 1 0 00-1.42 0l-7.45 7.46-3.13-3.14a1.022 1.022 0 00-1.42 1.47l3.84 3.84a1.001 1.001 0 001.42 0l8.16-8.16a1 1 0 000-1.47z"
        fill="#fff"
      />
    </svg>
  );
}

export default CheckIcon;
