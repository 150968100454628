import { Link } from "react-router-dom";
import styles from "./single-user.module.css";
import { useState } from "react";

type SingleUserProps = {
  id: number,
  image?: string,
  firstName: string,
  lastName: string,
};


function SingleUserList({ id, image, firstName, lastName }: SingleUserProps) {




  return (
    <div key={id} className={styles.single__user}>
      <div className={`flex items-center gap-1`}>
        <img
          className={styles.single__user__avatar}
          src={image}
          alt=""
        />
        <p className={`font-bold`}>
          {firstName} {lastName}
        </p>
      </div>

      <div className="">
        <p className="">{"Active"}</p>
      </div>

      <div className="">
        <Link to={`/overview/${id}`} className={`color-primary-50`}
        >
          View Details
        </Link>
      </div>
    </div>
  );
}

export default SingleUserList;
