import * as React from "react";

function SettingsIcon({ ...props }:any) {
  return (
    <svg
      width={25}
      height={25}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.435 10.05l-1.89-.63.89-1.78a1 1 0 00-.19-1.14l-2.13-2.13a1 1 0 00-1.15-.19l-1.78.89-.63-1.89a1 1 0 00-.94-.68h-3a1 1 0 00-.95.68l-.63 1.89-1.78-.89a1 1 0 00-1.14.19L3.985 6.5a1 1 0 00-.19 1.15l.89 1.78-1.89.63a1 1 0 00-.68.94v3a1 1 0 00.68.95l1.89.63-.89 1.78a1 1 0 00.19 1.14l2.13 2.13a1 1 0 001.15.19l1.78-.89.63 1.89a1 1 0 00.95.68h3a1 1 0 00.95-.68l.63-1.89 1.78.89a1 1 0 001.13-.19l2.13-2.13a1 1 0 00.19-1.15l-.89-1.78 1.89-.63a1 1 0 00.68-.94v-3a1 1 0 00-.68-.95zm-1.32 3.23l-1.2.4a2 2 0 00-1.16 2.82l.57 1.14-1.1 1.1-1.11-.6a2 2 0 00-2.79 1.16l-.4 1.2h-1.59l-.4-1.2a2 2 0 00-2.82-1.16l-1.14.57-1.1-1.1.6-1.11a2 2 0 00-1.16-2.82l-1.2-.4v-1.56l1.2-.4a2 2 0 001.16-2.82l-.57-1.11 1.1-1.1 1.11.57a2 2 0 002.82-1.16l.4-1.2h1.56l.4 1.2a1.999 1.999 0 002.82 1.16l1.14-.57 1.1 1.1-.6 1.11a1.998 1.998 0 001.16 2.79l1.2.4v1.59zm-8-4.78a4 4 0 100 8 4 4 0 000-8zm0 6a2 2 0 110-4 2 2 0 010 4z"
        fill="#303030"
      />
    </svg>
  );
}

export default SettingsIcon;
