import GoBack from "components/GoBack";
import useDocumentTitle from "hooks/useDocumentTitle";
import forgot_password_img from "assets/images/forgot-password.png";
import login_img from "assets/images/login.png";
import logo from "assets/images/logo.png";
import reset_password_img from "assets/images/reset-password.png";
import signup_img from "assets/images/signup.png";
import { Outlet, useLocation } from "react-router-dom";

import styles from "./layout.module.css";

export default function AuthLayout() {
  useDocumentTitle();

  const { pathname } = useLocation();
  let frame_illustraion =
    pathname === "/"
      ? login_img
      : pathname === "/sign-up"
      ? signup_img
      : pathname === "/forgot-password"
      ? forgot_password_img
      : pathname === "/reset-password"
      ? reset_password_img
      : "";
  return (
    <main className={`${styles.auth__container}`}>
      <div className={styles.auth__frame}>
        <img
          className={"logo"}
          src={logo}
          alt="Person clicking on computer illustration"
          loading="lazy"
        />
        <img
          className={styles.desc__img}
          src={frame_illustraion}
          alt="Person clicking on computer illustration"
          loading="lazy"
        />
      </div>

      <div className={styles.auth__content}>
        <div className={`${styles.auth__content__head} flex`}>
          <GoBack />
        </div>

        <div className={styles.auth__content__body}>
          <Outlet />
        </div>

        <div className={styles.auth__content__footer}></div>
      </div>
    </main>
  );
}
