import { Outlet } from "react-router-dom";

import Sidebar from "layouts/ConsoleLayout/Sidebar";
import Header from "./Header";

import styles from "./console-layout.module.css";
import useDocumentTitle from "hooks/useDocumentTitle";
import Navbar from "components/Navbar";

export default function ConsoleLayout() {

  useDocumentTitle();

  return (
    <main className={styles.wrapper}>

      <Sidebar />

      <section className={styles.content}>
        <Header />
        <Outlet />
        <div className={styles.padder}></div>
      </section>
      
      <Navbar />
    </main>
  );
}
