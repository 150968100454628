import FolderIcon from "components/icons/FolderIcon";
import useGetData from "hooks/useGetData";
import { useLayoutEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import deleteData from "utils/deleteData";
import AdCard from "./AdCard";
import styles from "./advertisments.module.css";
import { Advert, ApiGetAdvertsResponse } from "./types";

function Advertisments() {

    const { data } = useGetData<ApiGetAdvertsResponse>("/dashboard/advertisement");
    const [adverts, setAdverts] = useState<Advert[] | undefined>(undefined);

    const handleRemove = (id: Advert["id"], companyName: string) => {
      if (!id) return toast.error("Invalid ID");
      
      toast.promise(deleteData(`/admin/delete-advertisement/${id}`), {
        loading: `Deleting ${companyName} advert - ID: ${id}`,
        success: `Advert ${id} deleted`,
        error: `Error deleting advert`,
      });
      
      setAdverts(n => n?.filter(ad => ad.id !== id));
    };

    useLayoutEffect(() => {
      setAdverts(data?.data);
    }, [data])

  return (
    <div className={`${styles.container} space-y-1`}>

    <div className={styles.create__ads}>
        <Link to={"/advertisments/create"} className={styles.create__button}>
            <FolderIcon />
            <span className="">Add New</span>
        </Link>
    </div>

      <div className={styles.ads__wrapper}>
        {adverts?.map((advert) => (
            <AdCard 
                id={advert.id}
                companyName={advert.companyName}
                file={advert.file}
                status={advert.status}
                endDate={advert.endDate}
                actions
                handleRemove={handleRemove}
            />
        ))}
      </div>
    </div>
  );
}

export default Advertisments;
