import { AuthUser } from "contexts/AuthUser";
import { useContext, useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import toast from "react-hot-toast";

export default function ProtectedRoute() {
  const { user } = useContext(AuthUser);

  useEffect(() => {
    if (!user) {
      toast.error("Unauthorized access not allowed");
    }
  }, [user])

  if (!user) {
    return <Navigate to="/"></Navigate>;
  }
  
  return <Outlet />;
}
