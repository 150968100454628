import { getCookie, removeCookie, setCookie } from 'typescript-cookie';

export const getLocalUser = ():any => {
    return JSON.parse(localStorage.getItem('user') || `null`);
}

export const setLocalUser = (user:any) => {
    localStorage.setItem('user', JSON.stringify(user));
}

export const getLocalRefreshToken = ():string|undefined => {
    return getCookie('refreshToken');
}

export const getLocalAccessToken = ():string|undefined => {
    return getCookie('accessToken');
}

export const updateLocalAccessToken = (token:string) => {
    setCookie('accessToken', token);
}

export const removeUser = () => {
    localStorage.setItem('user', JSON.stringify(null));
    removeCookie('token');
    removeCookie('accessToken');
    removeCookie('refreshToken');
}