import { AuthUser } from "contexts/AuthUser";
import { useContext, useLayoutEffect } from "react";
import toast from "react-hot-toast";
import { Navigate } from "react-router-dom";
import { removeUser } from "utils/TokenService";

function Logout() {
  const { setUser } = useContext(AuthUser);

  const deauth = () => new Promise((resolve, reject) => {
    removeUser();
    setUser(null);
    return resolve(null);
  });
  
  useLayoutEffect(() => {
    toast.promise(deauth(), {
      loading: "Deauthorizing your tokens",
      error: "Unable to deauthorize",
      success: "Deauthorizing successful",
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <Navigate to="/"></Navigate>;
}

export default Logout;
