import React from "react";
import styles from "./rewards.module.css";
import { FormDataProps, Reward } from "./types";

type Props = {
  setTogglePreview: React.Dispatch<React.SetStateAction<boolean>>;
  EnumIDs?: any;
  rewards?: Reward[] | undefined;
  formData: FormDataProps;
  setFormData?: React.Dispatch<any>;
  className: string
};

function Preview({ setTogglePreview, formData, className }: Props) {
  return (
    <div className={`space-y-2 ${className}`}>
      <div className={`${styles.buttons_group} justify-end`}>
        <button
          className={`${styles.button} ${styles.disabled_button}`}
          onClick={() => setTogglePreview(false)}
        >
          Go Back
        </button>
      </div>

      <div className={styles.preview}>
        {Object.entries(formData)
          .flat()
          .filter((n) => typeof n !== "string")
          .map(
            (form) =>
              typeof form !== "string" && (
                <div className={`flex flex-col gap-1`}>
                  <h1 className="">{form.type} Reward</h1>

                  <div
                    className=""
                    dangerouslySetInnerHTML={{ __html: form.text }}
                  ></div>
                </div>
              )
          )}
      </div>
    </div>
  );
}

export default Preview;
