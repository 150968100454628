import axios from "utils/customAxios";

export default async function deleteData(url: string, opts?: any) {
  try {
    const response = await axios.delete(url, {...opts});
    if(!response){
      return { status: false, data: "Network Error. Failed to fetch" }
    }

    return response?.data;
  } catch (err: any) {
    throw new Error(err);
  }
}
