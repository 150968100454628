import React, { useState } from "react";
import styles from "./tabs.module.css";

type TabConfig = {
  tabHeaderVisible?: Boolean;
  tabHeaderCSS?: string;
  tabBodyCSS?: string;
};

type TabsProps = {
  headings: string[];
  contents: React.ReactNode[];
  config?: TabConfig;
};

const defaultConfig = {
  tabHeaderVisible: true,
  tabHeaderCSS: "",
  tabBodyCSS: "",
};

function Tabs({ headings, contents, config }: TabsProps) {
  const [activeTab, setActiveTab] = useState(0);
  config = { ...defaultConfig, ...config };

  return (
    <div className={styles.tabs}>
      {config?.tabHeaderVisible && (
        <div className={`${styles.tab__header} ${config?.tabHeaderCSS}`}>
          {headings.map((heading, index) => (
            <div
              key={index}
              className={`${styles.tab__heading} ${
                activeTab === index ? styles.tab__active : ""
              }`}
              onClick={() => setActiveTab(index)}
            >
              <span className="">{heading}</span>
            </div>
          ))}
        </div>
      )}

      <div className={`${styles.tab__body} ${config?.tabBodyCSS}`}>
        {contents?.map((content, index) => (
          <div
            key={index}
            className={`${styles.tab__content} ${
              !config?.tabHeaderVisible
                ? styles.tab__content__active
                : activeTab === index
                  ? styles.tab__content__active
                  : ""
            }`}
            onClick={() => setActiveTab(index)}
          >
            {content}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Tabs;
