import axios from "axios";
import {
    getLocalAccessToken,
    getLocalRefreshToken,
    getLocalUser,
    removeUser,
    updateLocalAccessToken,
} from "utils/TokenService";

const axios_instance = axios.create({
    baseURL: process.env.REACT_APP_API,
    headers: {
        "Content-Type": "application/json",
    },
});

axios_instance.interceptors.request.use(
    (config) => {
        const token = getLocalAccessToken();
        if (token) {
            config.headers["Authorization"] = "Bearer " + token; // for Spring Boot back-end
            // config.headers["x-access-token"] = token; // for Node.js Express back-end
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axios_instance.interceptors.response.use(
    (response) => response,
    async (err) => {
        // console.log(err);
        const originalConfig = err.config;

        if (originalConfig.url !== "/auth/login" && err.response) {
            // Access Token was expired
            if (err.response.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true;

                try {
                    const resp = await axios_instance.post(
                        "/auth/refresh-token",
                        {
                            email: getLocalUser()?.email,
                            refreshToken: getLocalRefreshToken(),
                        }
                    );

                    if (resp.data) {
                        const { accessToken } = resp.data;
                        updateLocalAccessToken(accessToken);
                        return axios_instance(originalConfig);
                    }

                    return { ...err.response, data: null };
                } catch (_error) {
                    return Promise.reject(_error);
                }
            }

            // If user tries to access route without a valid refreshToken
            if (err.response.status > 499 && !originalConfig._retry) {
                originalConfig._retry = true;
                removeUser();
                return { ...err.response, data: null };
            }
        }

        // Return response if other error handling fails
        // Promise.reject(err);
        return err.response;
    }
);

export default axios_instance;
