import axios from "utils/customAxios";

export default async function postData(url: string, payload: any, opts?: any) {
  try {
    const { data } = await axios.post(url, payload, opts);
    return data;
  } catch (err: any) {
    throw new Error(err);
  }
}
